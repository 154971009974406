import { IFilterAttributes } from "./IFilter";

function getDynamicValue(object: any) {
	if (typeof object === "object") {
		return object["name"] ?? object["payRate"] ?? "";
	}
	return object;
}

export class DynamicPersonAdapter {
	static adapt(person: any): any {
		const adaptedPerson: any = {};

		Object.keys(person).forEach((key) => {
			if (person[key]) {
				const value = person[key];
				if (Array.isArray(value) && value.length > 0) {
					adaptedPerson[key] = value
						.reduce((accumulator, currentValue) => `${accumulator + getDynamicValue(currentValue)} `, "")
						.trimEnd();
				} else if (typeof value === "object" && value !== null) {
					adaptedPerson[key] = getDynamicValue(value);
				} else {
					adaptedPerson[key] = value;
				}
			}
		});

		return adaptedPerson;
	}
}

export const filterRows = (rows: any, keys: string[], filterAttributes: IFilterAttributes, currentRows: any) => {
	const map = rows.map((row: any, idx: number) => {
		const matchingRows = keys.every((key) => {
			let meetCondition = false;

			if (!row[key]) return meetCondition;
			if (filterAttributes[key].filterOperator === "contains") {
				meetCondition = row[key].toLowerCase().includes(filterAttributes[key].value.toLowerCase());
			}
			if (filterAttributes[key].filterOperator === "equals") {
				meetCondition = row[key].toLowerCase() === filterAttributes[key].value.toLowerCase();
			}
			if (filterAttributes[key].filterOperator === "is not") {
				meetCondition = !row[key].toLowerCase().includes(filterAttributes[key].value.toLowerCase());
			}
			return meetCondition;
		});

		if (matchingRows) {
			return currentRows[idx];
		}
		return null;
	});
	return map.filter((row: any) => row !== null);
};

export const updateColumns = (columns: any, keys: any, setColumnsToFilter: any) => {
	const newColumns = columns.filter((column: any) => {
		const field = keys.includes(column.field);
		return !field;
	});
	setColumnsToFilter(newColumns);
};

export const OPERATOR_OPTIONS: string[] = ["contains", "equals", "is not"];
