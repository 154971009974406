import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Badge, ThemeProvider } from "@mui/material";
import { badgeTheme, notificationBadgeStyle } from "./NotificationsBadgeStyle";

export function NotificationBadge({ number }: { number: number }) {
	const { notificationBadge } = notificationBadgeStyle;

	return (
		<ThemeProvider theme={badgeTheme}>
			<Badge
				data-testid='notification-badge'
				sx={{ notificationBadge }}
				badgeContent={number}
				color='primary'
				showZero
				title='badge'
			>
				<NotificationsNoneOutlinedIcon fontSize='large' />
			</Badge>
		</ThemeProvider>
	);
}
