import { IDataDefinitionSchema } from "../models/dataDefinition.model";
import { ViewTag } from "../models/dynamicViewTag.model";

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const fetchDataDefinitionSchemaFor = (page_id: string): IDataDefinitionSchema => {
	const contractValues = ["PART_TIME", "FULL_TIME"];
	const statusValues = [
		{ name: "assigned" },
		{ name: "proposed" },
		{ name: "unassigned" },
		{ name: "training" },
		{ name: "internal role" },
		{ name: "reserved" },
		{ name: "visible +1" },
		{ name: "freeing up soon" },
		{ name: "shadow available" },
		{ name: "shadow unavailable" },
		{ name: "partially assigned" },
		{ name: "part time +1" },
	];

	return {
		fields: {
			name: { label: "name", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			lastName: { label: "lastName", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			ioetId: { label: "ioetId", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			ioetEmail: { label: "ioetEmail", view_tag: ViewTag.email, frozen: false, nullable: true },
			contractType: {
				label: "contractType",
				view_tag: ViewTag.select,
				frozen: false,
				nullable: true,
				valueOptions: contractValues,
			},
			startDate: { label: "startDate", view_tag: ViewTag.date, frozen: false, nullable: true },
			endDate: { label: "endDate", view_tag: ViewTag.date, frozen: false, nullable: true },
			timeOnTheJob: { label: "timeOnTheJob", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			status: { label: "status", view_tag: ViewTag.chip, frozen: false, nullable: true, multiSelectOptions: statusValues },
			workTitles: { label: "ioetRole", view_tag: ViewTag.object_modal, frozen: false, nullable: true },
			level: { label: "level", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			country: { label: "country", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			office: { label: "office", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			gender: { label: "gender", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			englishLevel: { label: "englishLevel", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			promisedReviewDate: { label: "promisedReviewDate", view_tag: ViewTag.date, frozen: false, nullable: true },
			compensation: { label: "compensation", view_tag: ViewTag.object_modal, frozen: false, nullable: true },
			timeAtIoet: { label: "timeAtIoet", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			reviewDate: { label: "reviewDate", view_tag: ViewTag.simple_string, frozen: false, nullable: true },
			employmentStatus: { label: "employmentStatus", view_tag: ViewTag.object_modal, frozen: false, nullable: true },
		},
	} as const;
};

export const columnsHiddenByDefault = {
	timeOnTheJob: false,
	country: false,
	gender: false,
	englishLevel: false,
	compensation: false,
	promisedReviewDate: false,
	timeAtIoet: false,
	reviewDate: false,
};
