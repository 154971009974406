import { Dict } from "src/v2/models";
import { PRIMARY_COLOR } from "src/constants/colors";
import { BasicInformationSection } from "./BasicInformationSection/BasicInformationSection";
import { BankInformationSection } from "./BankInformationSection/BankInformationSection";
import { EducationInformationSection } from "./EducationInformationSection/EducationInformationSection";
import { EmergencyContactSection } from "./EmergencyContactSection/EmergencyContactSection";

export function PersonalTab(personInformation: Dict, key: string) {
	const { personInformation: personData } = personInformation;

	return (
		<div style={{ paddingLeft: "5%" }} key={key} data-testid='personal-tab-container'>
			<BasicInformationSection personInformation={personData} />
			<hr style={{ backgroundColor: PRIMARY_COLOR, height: 5, marginRight: "10%" }} />
			<BankInformationSection bankAccountInformation={personData.bankAccount} />
			<hr style={{ backgroundColor: PRIMARY_COLOR, height: 5, marginRight: "10%" }} />
			<EducationInformationSection educationInformation={personData.education} />
			<hr style={{ backgroundColor: PRIMARY_COLOR, height: 5, marginRight: "10%" }} />
			<EmergencyContactSection personInformation={personData.emergencyContact} />
		</div>
	);
}
