function pxToRem(value:any) {
  return `${value / 16}rem`;
}

const FONT_PRIMARY = 'Barlow, Roboto, Helvetica, Arial, sans-serif';

const typography = {
  fontFamily: FONT_PRIMARY,
  h1: {
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: pxToRem(46),
  },
  h2: {
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: pxToRem(36),
  },
  h3: {
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: pxToRem(26),
  },
  h4: {
    fontWeight: 500,
    lineHeight: 1.6,
    fontSize: pxToRem(22),
  },
  h5: {
    fontWeight: 400,
    lineHeight: 1.6,
    fontSize: pxToRem(18),
  },
  h6: {
    fontWeight: 400,
    lineHeight: 1.6,
    fontSize: pxToRem(17),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.6,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1.6,
    fontSize: pxToRem(14),
  },
  body1: {
    fontWeight: 400,
    lineHeight: 1.6,
    fontSize: pxToRem(16),
  },
  body2: {
    fontWeight: 400,
    lineHeight: 1.6,
    fontSize: pxToRem(14),
  },
};

export default typography;
