import { ForwardRefExoticComponent, RefAttributes, forwardRef } from "react";
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from "@mui/material";
import { IAlertProps, RefType } from "./types";

const Alert: ForwardRefExoticComponent<IAlertProps & RefAttributes<HTMLDivElement>> = forwardRef(({
  textAlert,
  severity,
  variant,
  textAlertTitle,
  onClose
}: IAlertProps,
  innerRef?: RefType
) => {
  const alertTitle = textAlertTitle ? <MuiAlertTitle>{textAlertTitle}</MuiAlertTitle> : undefined

  return (
    <MuiAlert severity={severity} variant={variant} ref={innerRef} onClose={onClose}>
      {alertTitle}
      {textAlert}
    </MuiAlert>
  );
});

export default Alert;
