import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Divider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TEXTFIELD_BACKGROUND_COLOR, mainColor } from "src/constants/colors";
import { ILoadingContextState } from "src/contexts/IContexts";
import LoadingContext from "src/contexts/LoadingStateContext";
import { Avatar } from "src/v2";
import { SideBarProfile } from "src/v2/components/SideBarProfile/sideBarProfile";
import { CompensationTab } from "src/v2/pages/PersonProfile/PersonProfileTabs/CompensationTab/CompensationTab";
import NavTabs from "src/v2/components/organisms/NavTabs/NavTabs";
import { themePeopleApp } from "src/v2/theme/provider/ThemePeopleApp";
import { useDynamicViewTagContext } from "src/v2/hooks";
import usePersonInformation from "src/v2/hooks/usePesonInformation";
import { columnsHiddenByDefault } from "src/v2/services/dataDefinitionSchema.service";
import Loading from "../../components/Loading/Loading";
import { Header } from "../../components/Header/header";
import { InfoTab } from "../../components/InfoTab/InformationTab";
import { Table } from "../../components/organisms/Table/Table";
import { Dict } from "../../models/dynamicModel.model";
import { NotesTab } from "./PersonProfileTabs/NotesTab/NotesTab";
import { PersonalTab } from "./PersonProfileTabs/PersonalTab/PersonalTab";

const DEFAULT_TABS = ["Personal", "Job", "Compensation", "ioet University", "Notes"];

export function PersonProfile() {
	const { personId } = useParams();
	const { fetchPersonData, personInformation } = useDynamicViewTagContext();
	const { personInformationMock, initialize } = usePersonInformation();
	const { loading } = useContext(LoadingContext) as ILoadingContextState;

	useEffect(() => {
		const initializeData = async () => {
			await initialize(personId!);
		};

		initializeData();
	}, []);
	useEffect(() => {
		const initializeData = async () => {
			await fetchPersonData(personId!);
		};

		initializeData();
	}, []);

	if (!personInformation || loading) {
		return <Loading />;
	}

	const handleAddNewJob = () => {
		return null;
	};

	const formatColumns = (columns: Dict, actionsAvailable: boolean): GridColDef<any, any, any>[] => {
		const formatedColumns = new Set();
		columns.forEach((column: Dict) => {
			Object.keys(column).forEach((key) => {
				formatedColumns.add(key);
			});
		});

		const customFields = Array.from(formatedColumns).map((key) => {
			return { field: key, headerName: key, minWidth: 200, headerAlign: "left", align: "left" };
		});
		if (actionsAvailable) {
			customFields.push({ field: "actions", headerName: "Actions", minWidth: 200, headerAlign: "left", align: "left" });
		}
		return customFields as GridColDef<any, any, any>[];
	};
	return (
		<div
			data-testid='person-profile'
			style={{
				display: "grid",
				gridTemplateColumns: "300px minmax(0, 1fr)",
				gridTemplateRows: "150px 100px auto",
				width: "100%",
        minHeight: "100vh"
			}}
		>
			<div
				data-testid='avatar-container'
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					gridColumnStart: 1,
					gridRowStart: 1,
					gridRowEnd: 3,
					background: "#FF3131",
				}}
			>
				<Avatar
					alt=''
					height='14rem'
					width='14rem'
					srcImage='https://t4.ftcdn.net/jpg/03/31/69/91/360_F_331699188_lRpvqxO5QRtwOM05gR50ImaaJgBx68vi.jpg'
				/>
			</div>
			<Header data-testid='header-container' personInformation={personInformation} />
			<div style={{ gridColumnStart: 1, gridRowStart: 3, backgroundColor: TEXTFIELD_BACKGROUND_COLOR, height:"100%" }}>
				<SideBarProfile data-testid='side-bar-profile-container' personInformation={personInformation} />
			</div>
			<div
				data-testid='nav-tabs-container'
				style={{ width: "100%", gridColumnStart: 2, gridColumnEnd: 3, gridRowStart: 2, gridRowEnd: 4 }}
			>
				<ThemeProvider theme={themePeopleApp}>
					<NavTabs
						labels={DEFAULT_TABS}
						tabs={[
							<PersonalTab personInformation={personInformation} key='personal-tab' />,
							<div key='section-2'>
								<InfoTab
									tittle='Job Information'
									textButton='+ Add Entry'
									onClick={handleAddNewJob}
									columns={formatColumns(personInformationMock?.jobInformation ?? [], true)}
									rows={personInformationMock?.jobInformation ?? []}
									toolBar={false}
									footer={false}
									icon={<ContactSupportIcon sx={{ color: mainColor }} fontSize='large' />}
								/>
								<Divider sx={{ marginTop: "10px", borderWidth: "medium" }} color={mainColor} />
								<Table
									columns={formatColumns(personInformationMock?.bambooData ?? [], false)}
									rowData={personInformationMock?.bambooData ?? []}
									customToolBar={false}
									hideFooter={false}
								/>
							</div>,
							<CompensationTab personInformation={personInformation} key='compensation-tab' />,
							<>section4</>,
							<NotesTab key='notes-tab' />,
						]}
						icons={[
							{ icon: "clipboard" },
							{ icon: "job" },
							{ icon: "compensation" },
							{ icon: "graduation" },
							{ icon: "notes" },
						]}
					/>
				</ThemeProvider>
			</div>
		</div>
	);
}
