import { PaletteColorOptions, PaletteOptions } from '@mui/material';

const PRIMARY: PaletteColorOptions = {
  light: '#5BD5FF',
  main: '#30BCED',
  dark: '#248EB2',
  contrastText: '#fff',
};

const SECONDARY: PaletteColorOptions = {
  light: '#C568C7',
  main: '#861388',
  dark: '#741775',
  contrastText: '#fff',
};

const SUCCESS: PaletteColorOptions = {
  light: '#CCFCFF',
  main: '#16BAC5',
  dark: '#0D6380',
  contrastText: '#fff',
};

const INFO: PaletteColorOptions = {
  light: '#DADADA',
  main: '#646464',
  dark: '#515151',
  contrastText: '#fff',
};

const WARNING: PaletteColorOptions = {
  light: '#FFDDCC',
  main: '#FF5E0A',
  dark: '#B24912',
  contrastText: '#fff',
};

const ERROR: PaletteColorOptions = {
  light: '#FFCCD0',
  main: '#FF3948',
  dark: '#B2121E',
  contrastText: '#fff',
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
};

const palette: { light: PaletteOptions } = {
  light: {
    ...COMMON,
    text: { primary: '#030303', secondary: '#717171', disabled: '#BCBBBB' },
    background: { paper: '#fff', default: '#F1F1F1' },
  },
};

export default palette;
