import { createTheme } from "@mui/material";
import tw from "twin.macro";

const initialTheme = createTheme({
	palette: {
		secondary: {
			main: "#702F8A",
			light: "#FFEAFF",
			dark: "#612A80",
		},
		success: {
			main: "#3ACA9F",
			contrastText: "#FFFFFF",
		},
		error: {
			main: "#FF1212",
		},
	},
});
const theme = createTheme(initialTheme, {
	palette: {
		buttonGrey: initialTheme.palette.augmentColor({
			color: {
				main: "#9DA3A7",
			},
			name: "buttonGrey",
		}),
	},
	components: {
		MuiButton: {
			styleOverrides: {
				containedPrimary: {
					...tw`bg-ioet-orange font-bold`,
					
				},
			},
		},
	},
});

export default theme;
