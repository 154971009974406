import { Divider, IconButton, Stack, Button } from "@mui/material";
import {
	GridRowId,
	GridCellModes,
	GridCellModesModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarQuickFilter,
	GridColDef,
} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import SortIcon from "@mui/icons-material/Sort";
import { Filter } from "../Filter/Filter";

interface CustomToolBarProps<T> {
	setCellModesModel: (value: GridCellModesModel) => void;
	selectedCell?: CustomSelectedCellProps;
	isCellEditingMemo: boolean;
	columns: GridColDef[];
	currentRows: T[];
	setFilterRow: (event: T[]) => void;
}

export interface CustomSelectedCellProps {
	id: GridRowId;
	field: string;
}

export function CustomToolBar<T>({
	setCellModesModel,
	selectedCell,
	isCellEditingMemo,
	columns,
	currentRows,
	setFilterRow,
}: CustomToolBarProps<T>) {
	const handleSaveClick = () => {
		setCellModesModel({
			[selectedCell!.id]: {
				[selectedCell!.field]: {
					mode: GridCellModes.View,
				},
			},
		});
	};

	const handleCancelClick = () => {
		setCellModesModel({
			[selectedCell!.id]: {
				[selectedCell!.field]: {
					mode: GridCellModes.View,
					ignoreModifications: true,
				},
			},
		});
	};

	return (
		<GridToolbarContainer data-testid='custom-tool-bar' style={{ display: "flex", justifyContent: "space-between" }}>
			<Stack direction='row'>
				<GridToolbarColumnsButton />
				<GridToolbarDensitySelector />
				<Filter columns={columns} currentRows={currentRows} setFilterRow={setFilterRow} />
				{/* TODO: here we can create a component for sort button */}
				<Button size='small' startIcon={<SortIcon />} onClick={()=>{console.log("sort functionality")} }>
					Sort
				</Button>
				{isCellEditingMemo && (
					<Stack sx={{ ml: "10px" }} direction='row' spacing={1}>
						<Divider sx={{ borderColor: "#00000033" }} orientation='vertical' flexItem />
						<IconButton data-testid='save-button' aria-label='save' size='small' onClick={handleSaveClick}>
							<SaveIcon style={{ color: "RoyalBlue" }} fontSize='small' />
						</IconButton>
						<IconButton data-testid='cancel-button' aria-label='delete' size='small' onClick={handleCancelClick}>
							<ClearIcon style={{ color: "RoyalBlue" }} fontSize='small' />
						</IconButton>
					</Stack>
				)}
			</Stack>

			<GridToolbarQuickFilter data-testid='search-bar' />
		</GridToolbarContainer>
	);
}
