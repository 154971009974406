import {
	actionViewTagComponent,
	arrayViewTagComponent,
	checkboxViewTagComponent,
	dateViewTagComponent,
	detailViewTagComponent,
	objectArrayViewTagComponent,
} from "../components/viewTagsComponents/simpleViewTags";

import { ViewTagComponentMapping } from "../models/dynamicViewTag.model";

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const fetchViewTagComponentMappingFor = (page_id: string): ViewTagComponentMapping => {
	return {
		simple_string: detailViewTagComponent,
		email: detailViewTagComponent,
		date: dateViewTagComponent,
		checkbox: checkboxViewTagComponent,
		details_button: actionViewTagComponent,
		ACTION: actionViewTagComponent,
		array: arrayViewTagComponent,
		object_modal: objectArrayViewTagComponent,
		select: detailViewTagComponent,
        chip: objectArrayViewTagComponent,
	};
};
