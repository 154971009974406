import Typography from "src/v2/components/atoms/Typography/Typography";
import { Avatar } from "src/v2";
import { IDataAvatar } from "./types";

function DataAvatar({ avatar, text }: IDataAvatar) {
	return (
		<div style={{ display: "flex",columnGap:"5%", alignItems: "center"}}>
			<Avatar srcImage={avatar.srcImage} alt={avatar.alt} height={avatar.height} width={avatar.width}/>
			<Typography variant={text.variant}>{text.children}</Typography>
		</div>
	);
}

export default DataAvatar;