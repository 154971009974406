import React from "react";
import { Avatar as MuiAvatar } from "@mui/material";
import Icon from "src/v2/components/atoms/Icon/Icon";
import { IAvatarProps } from "./types";

// TODO: Add support for images in library
function Avatar({ alt, src, height = "auto", width = "auto", srcImage }: IAvatarProps) {
	return src ? (
		<MuiAvatar alt={alt} sx={{ height: { height }, width: { width } }}>
			<Icon icon={src} />
		</MuiAvatar>
	) : (
		<MuiAvatar alt={alt} sx={{ height: { height }, width: { width } }} src={srcImage} />
	);
}

export default Avatar;
