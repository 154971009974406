import React from "react";

/** Returns true if the component has mounted (on the client!). Useful for avoiding hydration errors
 * and for Context Providers.  */
export default function useHasMounted() {
	const [hasMounted, setHasMounted] = React.useState(false);
	React.useEffect(() => {
		setHasMounted(true);
	}, []);
	return hasMounted;
}
