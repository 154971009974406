import Typography from "src/v2/components/atoms/Typography/Typography";
import TypographyGroup from "src/v2/components/molecules/TypographyGroup/TypographyGroup";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import IconGroup from "src/v2/components/molecules/IconGroup/IconGroup";
import DataAvatar from "src/v2/components/molecules/DataAvatar/DataAvatar";
import { joinNameLastName } from "src/utils/stringJoins";
import { Dict } from "src/v2/models";
import {snackBarColor, snackBarBackgroundColor} from "src/constants/colors";

export function SideBarProfile(personInformation: Dict) {
	const { personInformation: personData } = personInformation;
	const columnGap = "5%";
	return (
		<aside
			data-testid='side-bar-profile-container'
			style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					rowGap: 20,
					paddingTop: "5%",
					backgroundColor: snackBarColor,
					width: "100%",
					height: "100%",
				}}
			>
				<div
					data-testid='data-icon-container'
					style={{ display: "flex", flexDirection: "column", rowGap: 15, paddingLeft: "15%" }}
				>
					<DataIcon
						iconName={{ icon: "smartphone" }}
						text={{ children: personData.mobilePhone, variant: "body1" }}
						columnGap={columnGap}
					/>
					<DataIcon
						iconName={{ icon: "mail" }}
						text={{ children: personData.ioetEmail, variant: "body1" }}
						columnGap={columnGap}
					/>
				</div>
				<div data-testid='social-media-container' style={{ paddingLeft: "15%" }}>
					<IconGroup icons={[{ icon: "linkedin" }, { icon: "x" }, { icon: "facebook" }]} />
				</div>
				<div
					data-testid='hire-date-container'
					style={{ display: "flex", flexDirection: "column", rowGap: 15, paddingLeft: "15%" }}
				>
					<hr style={{ backgroundColor: snackBarBackgroundColor, height: 5, marginRight: "10%" }} />
					<Typography variant='body1' sx={{ fontWeight: "bold" }}>
						Hire Date
					</Typography>
					<TypographyGroup
						typographyList={[
							{ children: personData.startDate, variant: "body1", color: "inherit" },
							// TODO: "Implement in the backend a field that calculates the time in the job"
							{ children: personData.timeWork, variant: "body1", color: "inherit" },
						]}
					/>
				</div>
				<div
					data-testid='location-container'
					style={{ display: "flex", flexDirection: "column", rowGap: 15, paddingLeft: "15%" }}
				>
					<hr style={{ backgroundColor: snackBarBackgroundColor, height: 5, marginRight: "10%" }} />
					<DataIcon
						iconName={{ icon: "hexagons" }}
						text={{
							children:
								personData.jobInformation?.length > 0 ? personData.jobInformation[0].department : "Not Found",
							variant: "body1",
						}}
						columnGap={columnGap}
					/>
					<DataIcon
						iconName={{ icon: "mapPin" }}
						text={{ children: personData.country, variant: "body1" }}
						columnGap={columnGap}
					/>
					<DataIcon
						iconName={{ icon: "employmentStatus" }}
						text={{
							children:
								personData.statusCategory?.length > 0
									? personData.employmentStatus[0].statusCategory
									: "Not Found",
							variant: "body1",
						}}
						columnGap={columnGap}
					/>
					<DataIcon
						iconName={{ icon: "docSign" }}
						text={{ children: personData.contractType, variant: "body1" }}
						columnGap={columnGap}
					/>
				</div>
				<div
					data-testid='reports-to-container'
					style={{ display: "flex", flexDirection: "column", rowGap: 15, paddingLeft: "15%" }}
				>
					<hr style={{ backgroundColor: snackBarBackgroundColor, height: 5, marginRight: "10%" }} />
					<Typography variant='body1' sx={{ fontWeight: "bold" }}>
						Reports To
					</Typography>

					{personData.jobInformation?.map((item: Dict, index: number) => {
						const supervisorFullName = joinNameLastName(item.supervisorName, item.supervisorLastName);
						return (
							<div key={`dataAvatar-${index}`}>
								<DataAvatar
									text={{
										children: supervisorFullName,
										variant: "body1",
										color: "inherit",
									}}
									avatar={{
										srcImage: "default",
										alt: "default",
										width: "40px",
										height: "40px",
									}}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</aside>
	);
}
