import { joinNameLastName, objectArrayToString } from "src/utils/stringJoins";
import PersonHeaderData from "src/v2/components/organisms/PersonHeaderData/PersonHeaderData";
import { Dict } from "src/v2/models";

export function Header(personInformation: Dict) {
	const { personInformation: personData } = personInformation;
	const peopleFullName = joinNameLastName(personData.name, personData.lastName);
	return (
		<header
			data-testid='header-container'
			style={{ display: "flex", background: "linear-gradient(to right, #FF3131, #FF914D)" }}
		>
			<div data-testid='typography group' style={{ width: "80%", paddingTop: "1%", paddingLeft: "2%" }}>
				<PersonHeaderData
					name={peopleFullName}
					jobData={[
						{ children: personData.ioetId, variant: "h6", color: "common.white" },
						{ children: objectArrayToString(personData.status), variant: "h6", color: "common.white" },
						{
							children:
								personData.jobInformation && personData.jobInformation[0]
									? personData.jobInformation[0].jobTitle
									: "",
							variant: "h6",
							color: "common.white",
						},
					]}
				/>
			</div>
		</header>
	);
}
