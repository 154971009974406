import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { IoetIcons } from "src/v2/icons";
import { IIconProps } from "./types";

function Icon({ icon, size = 24, style }: IIconProps) {
	const [iconPath, setIconPath] = useState<string>("");

	useEffect(() => {
		const renderIcon = async () => {
			if (typeof icon !== "string") return icon;
			const iconModule = await IoetIcons[icon in IoetIcons ? icon : "default"];
			setIconPath(`${iconModule.default}`);
			return "";
		};

		renderIcon();
	}, [icon]);

	return typeof icon === "string" ? (
		<ReactSVG
			src={iconPath}
			style={style}
			afterInjection={(svg) => {
				svg.setAttribute("style", `display: flex; width: ${size}px; height: ${size}px`);
			}}
		/>
	) : (
		icon
	);
}

export default Icon;
