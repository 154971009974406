import TypographyGroup from "src/v2/components/molecules/TypographyGroup/TypographyGroup";
import Typography from "src/v2/components/atoms/Typography/Typography";
import { IPersonHeaderData } from "./types";

export default function PersonHeaderData({ name, jobData }: IPersonHeaderData) {
	return (
		<div>
			<Typography variant="h5" color="common.white"> <strong>{name}</strong> </Typography>
			<TypographyGroup typographyList={jobData} />
		</div>
	);
}
