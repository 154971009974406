import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { Dict } from "src/v2/models";
import { PRIMARY_COLOR } from "src/constants/colors";
import { Box } from "@mui/system";
import { TextField } from "src/v2/components/atoms/TextField";

export function BankInformationSection(bankAccountInformation: { bankAccountInformation: Dict[] }) {
	const { bankAccountInformation: personBankAccountData } = bankAccountInformation;
	const dataIconColumnGap = "1%";

	return (
		<Box
			data-testid='bank-account-information-container'
			sx={{
				width: {lg: "70%", md:"100%"},
				marginBottom: "3%"
			}}
		>
			<div style={{ paddingTop: "2%", paddingBottom: "3%"}}>
				<DataIcon
					iconName={{ icon: "bank" }}
					text={{ children: "Bank Account Information", variant: "h6", sx: { color: PRIMARY_COLOR, fontWeight: "bold" } }}
					columnGap={dataIconColumnGap}
				/>
			</div>
			{personBankAccountData?.map((item: Dict, index: number) => (
				<div style={{ marginBottom: "2%" }} key={`accountInfomation-${index}`}>
					<div style={{ marginBottom: "2%" }} data-testid='bank-account-information-swift'>
						<TextField
							id='swiftId'
							variant='outlined'
							label='SWIFT/BIC or ABA-Routing Number'
							value={ item.swiftBic ?? "" }
							size='small'
							sx={{ width: { md:"40%"} }}
							onChange={() => {}}
						/>
					</div>
					<div style={{ marginBottom: "2%"}} data-testid='bank-account-information-accountNumber'>
						<TextField
							id='accountNumberId'
							variant='outlined'
							label='BBAN-Account Number'
							value={ item.accountNumber ?? "" }
							size='small'
							sx={{ width: { md:"40%"} }}
							onChange={() => {}}
						/>
					</div>
					<div data-testid='bank-account-information-beneficiaryName'>
						<TextField
							id='beneficiaryNameId'
							variant='outlined'
							label='Beneficiary Name'
							value={ item.beneficiaryName ?? "" }
							size='small'
							sx={{ width: { md:"40%"} }}
							onChange={() => {}}
						/>
					</div>
				</div>
			))}
		</Box>
	);
}
