import { useState } from "react"
import { InputAdornment, TextField, ThemeProvider } from "@mui/material"
import { AutoStories, ArrowDropDownCircle, BorderColor, CalendarToday, CheckBox, Tag } from "@mui/icons-material"
import { IPropertyInput } from "../IInputs"
import { InputTheme } from "../InputStyleComponents"

export function PropertyInput({label, onChange=()=>{}} : IPropertyInput){
    const [input, setInput] = useState("")
    const onChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value)
        onChange(event)
    }
    return(
        <ThemeProvider theme={InputTheme}>
            <TextField
                id="property-name-input"
                placeholder={`${label} Name`}
                value={input}
                InputProps={{
                    startAdornment:(
                        <InputAdornment position="start">
                            {label === "Text" && <AutoStories color="secondary"/>}
                            {label === "Dropdown" && <ArrowDropDownCircle color="secondary"/>}
                            {label === "Date" && <CalendarToday color="secondary"/>}
                            {label === "Number" && <Tag color="secondary"/>}
                            {label === "Checkbox" && <CheckBox color="secondary"/>}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <BorderColor color="primary"/>
                        </InputAdornment>
                    ),
                }}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeLocal(event);
                }}
                focused
            />
        </ThemeProvider>
    )
}
