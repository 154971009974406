import { createContext, useMemo, useState } from "react";
import useHasMounted from "../hooks/useHasMounted";
import { IDataDefinitionSchema } from "../models/dataDefinition.model";
import { Dict, DynamicModel } from "../models/dynamicModel.model";
import { DynamicViewTagComponent, IDynamicViewTagProps, ViewTagComponentMapping } from "../models/dynamicViewTag.model";
import { fetchDataDefinitionSchemaFor } from "../services/dataDefinitionSchema.service";
import { fetchAllPeopleData, fetchAllTimeNotifications, getPersonInformation } from "../services/peopleData.service";
import { fetchViewTagComponentMappingFor } from "../services/viewTagComponentMapping.service";

interface DynamicViewModelState {
	RawPeopleData: DynamicModel[] | null;
	TimeNotificationData: DynamicModel | null;
	DynamicViewTagsComponents: DynamicViewTagComponent[];
	ViewTagComponentMapping: ViewTagComponentMapping | null;
	DataDefinitionSchema: IDataDefinitionSchema | null;
	personInformation: Dict | null;
}

interface DynamicViewModelActions {
	initialize: (page_id: string) => Promise<void>;
	renderDynamicModelField: (columnField: string) => null | ((value: any) => JSX.Element);
	fetchPersonData: (ioetId: string) => any;
}

interface DynamicViewTagContextValue extends DynamicViewModelState, DynamicViewModelActions {}

interface IDynamicViewTagsProviderProps {
	children: React.ReactNode;
}

export const DynamicViewTagContext = createContext<DynamicViewTagContextValue>({
	RawPeopleData: null,
	TimeNotificationData: null,
	DynamicViewTagsComponents: [],
	ViewTagComponentMapping: null,
	DataDefinitionSchema: null,
	initialize: async (): Promise<void> => {},
	renderDynamicModelField: () => null,
	fetchPersonData: async (): Promise<void> => {},
	personInformation: {},
});

function DynamicViewTagContextProvider({ children }: IDynamicViewTagsProviderProps) {
	const hasMounted = useHasMounted();

	const [rawPeopleData, setRawPeopleData] = useState<DynamicModel[] | null>(null);
	const [timeNotificationData, setTimeNotificationData] = useState<DynamicModel | null>(null);
	const [dataDefinition, setDataDefinition] = useState<IDataDefinitionSchema | null>(null);
	const [viewTagComponentMapping, setViewTagComponentMapping] = useState<ViewTagComponentMapping | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [personInformation, setPersonInformation] = useState<Dict | null>(null);

	const initialize = async (page_id: string) => {
		try {
			const getViewTagComponentMapping = fetchViewTagComponentMappingFor(page_id);
			const dataDefinitionSchema = fetchDataDefinitionSchemaFor(page_id);
			const getRawPeopleData = await fetchAllPeopleData();
			const getTimeNotifications = await fetchAllTimeNotifications();

			setRawPeopleData(getRawPeopleData);
			setTimeNotificationData(getTimeNotifications);
			setDataDefinition(dataDefinitionSchema);
			setViewTagComponentMapping(getViewTagComponentMapping);
		} catch (error) {
			// eslint-disable-next-line no-alert
			alert(`Error fetching  information: ${(error as Error).message}`);
		} finally {
			setLoading(false);
		}
	};

	const fetchPersonData = async (ioetId: string) => {
		setLoading(true);
		setPersonInformation(null);
		try {
			const data = await getPersonInformation(ioetId);
			setPersonInformation(data);
		} catch (error) {
			// eslint-disable-next-line no-alert
			alert(`Error fetching person information: ${(error as Error).message}`);
		} finally {
			setLoading(false);
		}
	};

	const renderDynamicModel = useMemo(() => {
		return (columnField: string) => {
			if (!dataDefinition || !viewTagComponentMapping) return null;

			const schemaFields = dataDefinition.fields;
			const { label, view_tag: viewTag, frozen } = schemaFields[columnField];

			const SelectedComponent = viewTagComponentMapping[viewTag as keyof ViewTagComponentMapping];

			return (value: JSX.Element) =>
				SelectedComponent && <SelectedComponent {...({ value, frozen, label } as IDynamicViewTagProps)} />;
		};
	}, [dataDefinition, viewTagComponentMapping]);

	const contextValue: DynamicViewTagContextValue = useMemo(
		() => ({
			RawPeopleData: rawPeopleData,
			TimeNotificationData: timeNotificationData,
			DynamicViewTagsComponents: [],
			ViewTagComponentMapping: viewTagComponentMapping,
			DataDefinitionSchema: dataDefinition,
			initialize,
			renderDynamicModelField: renderDynamicModel,
			fetchPersonData,
			personInformation,
		}),
		[rawPeopleData, timeNotificationData, viewTagComponentMapping, dataDefinition, personInformation]
	);

	if (!hasMounted) return null;

	return <DynamicViewTagContext.Provider value={contextValue}> {children} </DynamicViewTagContext.Provider>;
}

export default DynamicViewTagContextProvider;
