import { Divider, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Stack } from "@mui/system";
import { useState } from "react";

type BasicSelectProps = {
	values: string[] | undefined;
	optionsTitle?: string;
	variant?: "standard" | "outlined" | "filled";
	placeholder?: string;
	onChange?: (event: SelectChangeEvent) => void;
};
export default function BasicSelect({ values, optionsTitle, variant, placeholder, onChange }: BasicSelectProps) {
	const initialValue = values?.[0] ?? ["no status"];

	const [value, setValue] = useState(initialValue);
	const handleInputChange = (event: SelectChangeEvent) => {
		setValue(event.target.value as string);
	};
	// eslint-disable-next-line
	const selectedValue = typeof value == "string" ? value : value[0];
	const handleChange = (event: SelectChangeEvent) => {
		if (!onChange) {
			handleInputChange(event);
		} else {
			handleInputChange(event);
			onChange(event);
		}
	};

	return (
		<FormControl fullWidth>
			<InputLabel>{placeholder}</InputLabel>
			<Select
				variant={variant}
				size='small'
				onChange={handleChange}
				label={value}
				value={placeholder ? undefined : selectedValue}
				fullWidth
			>
				{optionsTitle && (
					<Stack direction='column' useFlexGap gap={1} sx={{ marginX: 2 }}>
						<InputLabel sx={{ fontWeight: "bold" }}>{optionsTitle}</InputLabel>
						<Divider />
					</Stack>
				)}

				{values?.map((item, index) => {
					return (
						<MenuItem key={index} value={item}>
							{item}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
