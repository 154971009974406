import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { IDatePickerProps } from "./types";

const DatePicker: React.FC<IDatePickerProps> = (
	{
		label,
		value,
		disabled,
		closeOnSelect,
		defaultValue,
		onChange,
		sx,
		slotProps = {}
	}) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MuiDatePicker
				label={label}
				value={value}
				onChange={onChange}
				disabled={disabled}
				closeOnSelect={closeOnSelect}
				defaultValue={defaultValue}
				sx={sx}
				slotProps={slotProps}
			/>
		</LocalizationProvider>
	);
};
export default DatePicker;
