import { createTheme } from "@mui/material";

export const notificationBadgeStyle = {
	notificationBadge: {
		position: "absolute",
		top: "-8px",
		right: "-8px",
		backgroundColor: "#fbe3d6",
		color: "black",
		borderRadius: "50%",
		width: "20px",
		height: "20px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: "12px",
		fontWeight: "bold",
	},
};

export const badgeTheme = createTheme({
	palette: {
		primary: {
			main: "#fbe3d6",
		},
		secondary: {
			main: "#000000",
		},
	},
});
