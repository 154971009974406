import { stringToTitleCase } from "../../../../utils/parsers";
import { ADMIN_ROLE_NAME } from "../../../../constants/authConstant";
import { UserInformationContainer } from "./UserInformationStyleComponents";
import { UserInformationRow } from "../../atoms";
import { UserInformationProps, UserRowInformation } from "./types";

function UserInformation({ user, showEmail, showAdminRole, showName }: UserInformationProps) {
	if (!ADMIN_ROLE_NAME) {
		throw new Error("Admin role name should be defined");
	}

	const stylizedRole = (user?.roles.includes(ADMIN_ROLE_NAME) && stringToTitleCase(ADMIN_ROLE_NAME))?.toString();

	const textRows: UserRowInformation[] = [
		{
			rowCondition: showName,
			rowIcon: "user",
			rowText: user?.name,
		},
		{
			rowCondition: showAdminRole,
			rowIcon: "users",
			rowText: stylizedRole,
			rowIconColor: "#999999",
			rowtextWeight: "light",
		},
		{
			rowCondition: showEmail,
			rowIcon: "mail",
			rowText: user?.email,
			rowIconColor: "#999999",
			rowtextWeight: "light",
		},
	];

	return (
		<UserInformationContainer>
			{textRows
				.filter((row) => row.rowCondition)
				.map((row, key) => (
					<div key={key}>
						<UserInformationRow
							icon={row.rowIcon}
							text={row.rowText}
							iconColor={row.rowIconColor}
							textWeight={row.rowtextWeight}
						/>
					</div>
				))}
		</UserInformationContainer>
	);
}

export default UserInformation;
