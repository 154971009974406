import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { Box, MenuItem } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button } from "../Button/Button";
import { DynamicPersonAdapter, OPERATOR_OPTIONS, filterRows, updateColumns } from "./utils";
import PopoverFilter from "./PopoverFilter";
import { IFilterAttributes, IFilterProps } from "./IFilter";

export function Filter<T>({ columns, currentRows, setFilterRow }: IFilterProps<T>) {
	const dynamicAdaptedPersonList: T[] = currentRows.map((person) => DynamicPersonAdapter.adapt(person));
	const [open, setIsOpen] = useState<boolean>(false);
	const [filterAttributes, setFilterAttributes] = useState<IFilterAttributes>({});
	const [operator, setOperator] = useState<string>("contains");
	const [columnsToFilter, setColumnsToFilter] = useState<GridColDef[]>(columns);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	let keys = Object.keys(filterAttributes);
	useEffect(() => {
		updateColumns(columns, keys, setColumnsToFilter);
		const newRows = filterRows(dynamicAdaptedPersonList, keys, filterAttributes, currentRows);
		setFilterRow(newRows.length > 0 ? newRows : currentRows);
	}, [filterAttributes]);

	const handleColumnSelection = (value: string) => {
		if (value) {
			setFilterAttributes({
				...filterAttributes,
				[value]: { value: "", filterOperator: "", openPopover: false },
			});
		}
		setIsOpen(!open);
	};

	const handleClose = (filter: string) => {
		setFilterAttributes({
			...filterAttributes,
			[filter]: { ...filterAttributes[filter], openPopover: false },
		});
	};

	const handleOpenFilter = () => {
		setIsOpen(!open);
	};

	const handleOpenData = (filter: string, event: MouseEvent<HTMLButtonElement> | null) => {
		setFilterAttributes({
			...filterAttributes,
			[filter]: { ...filterAttributes[filter], openPopover: true },
		});
		setAnchorEl(event?.currentTarget as HTMLButtonElement);
	};

	const handleOperator = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setOperator(value);
	};

	const handleDeleteFilter = (filter: string) => {
		const filterAttributesToDelete = { ...filterAttributes };

		const deletedFilterAttributes = delete filterAttributesToDelete[filter]
			? { ...filterAttributesToDelete }
			: { ...filterAttributes };
		keys = Object.keys(deletedFilterAttributes);
		setFilterAttributes(deletedFilterAttributes);
	};

	const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFilterAttributes((prev: IFilterAttributes) => {
			const newFilterAttributes: IFilterAttributes = {
				...prev,
				[name]: { value: value.toLowerCase(), filterOperator: operator, openPopover: true },
			};

			return newFilterAttributes;
		});
	};

	const handleResetFilters = () => {
		setFilterAttributes({});
		setIsOpen(false);
		setOperator(OPERATOR_OPTIONS[0]);
		setColumnsToFilter(columns);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "row" }}>
			<>
				<Button onClick={handleOpenFilter}>
					<FilterListIcon /> Filters
				</Button>
				{keys.length > 0 && (
					<Button onClick={handleResetFilters}>
						<DeleteForeverIcon />
					</Button>
				)}
			</>

			{open && (
				<Box
					sx={{
						width: "20%",
						backgroundColor: "#9a9a9a",
						color: "white",
						position: "absolute",
						zIndex: 1,
						marginTop: "2rem",
					}}
				>
					{columnsToFilter.map((column, idx) => (
						<MenuItem
							component='div'
							sx={{ cursor: "pointer", display: "fit-content", width: "100%" }}
							key={`${idx}-${column.field}`}
							onClick={() => handleColumnSelection(column.field)}
						>
							{column.field}
						</MenuItem>
					))}
				</Box>
			)}
			{keys.length > 0 && (
				<Box sx={{ display: "flex", zIndex: 2 }}>
					{keys.map((filter) => (
						<Box key={`${filter}-popover`}>
							<Button onClick={(event) => handleOpenData(filter, event)} type='optionFilter'>
								{filter}
							</Button>

							<PopoverFilter
								filter={filter}
								filterAttributes={filterAttributes}
								anchorEl={anchorEl}
								handleClose={handleClose}
								handleOperator={handleOperator}
								handleInput={handleInput}
								handleDeleteFilter={handleDeleteFilter}
								operator={operator}
							/>
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
}
