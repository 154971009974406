import { InputAdornment, TextField, Box } from "@mui/material";
import { AddSharp, MoreHorizSharp } from "@mui/icons-material";
import React, { useState } from "react";
import BasicModal from "../Modal/BasicModal";

export function DropdownOptions() {
    const [input, setInput] = useState("");
    const [options, setOptions] = useState<string[]>([]);
    const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
    const handleAddOption = () => {
        if(input !== ""){
            setOptions([...options, input]);
            setInput("");
        }
    };

    return (
        <div>
            <TextField
                value={input}
                placeholder="Add an option"
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleAddOption()}
                    InputProps={
                        {
                            startAdornment: (
                                <InputAdornment position="start" onClick={() => handleAddOption()}>
                                    <AddSharp/>
                                </InputAdornment>
                            )
                        }
                    }
            />
            <Box>
                {
                    options.map((option: string, index: number) => (
                        <div key={index} role="listitem">
                            <MoreHorizSharp onClick={handleOpen}/>
                            {option}
                        </div>
                    ) )
                }
            </Box>
            <BasicModal handleClose={handleClose} open={open}>
                <div>Edit Option Section</div>
            </BasicModal>
        </div>
    )
}
