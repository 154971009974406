import { IconButton } from "src/v2/components/atoms/IconButton";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { GridColDef } from "@mui/x-data-grid";

export const notesDataTable = () => {
	const columns: GridColDef[] = [
		{ field: "date", headerName: "Date", width: 150, sortable: false },
		{ field: "author", headerName: "Author", width: 150, sortable: false },
		{
			field: "category",
			headerName: "Category",
			width: 150,
			type: "string",
			sortable: false,
		},
		{
			field: "notes",
			headerName: "Notes",
			width: 304,
			type: "string",
			sortable: false,
		},
		{
			field: "",
			sortable: false,
			minWidth: 100,
			align: "center",
			headerAlign: "center",
			renderCell(params) {
				return (
					<IconButton
						icon={<ModeEditOutlinedIcon sx={{ backgroundColor: "none" }} />}
						title='Edit'
						style={{
							"boxShadow": "none",
							":hover": { backgroundColor: "transparent" },
							"backgroundColor": "transparent",
						}}
						onClick={() => {}}
						tooltipPlacement='right'
						dataTest='editIcon'
					/>
				);
			},
		},
	];
	return columns;
};
