import { Divider } from "@mui/material";
import { notesDataTable } from "src/utils/notesDataTable";
import { mainColor } from "src/constants/colors";
import { InfoTab } from "../../../../components/InfoTab/InformationTab";

interface INotesTabProps {
	key: string;
}

export function NotesTab(props: INotesTabProps) {
	const handleAddNewJob = () => {
		return null;
	};
	const rowHR = [
		{
			date: "YYYY-MM-DD",
			author: "Full Name",
			category: "Category Field",
			notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in nisi bibendum, mollis nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in nisi bibendum, mollis nibh.",
		},
	];
  const rowClient = [
		{
			date: "YYYY-MM-DD",
			author: "Full Name",
			category: "Category Field",
			notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in nisi bibendum, mollis nibh.",
		},
	];
  const rowPath = [
		{
			date: "YYYY-MM-DD",
			author: "Full Name",
			category: "Category Field",
			notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in nisi bibendum, mollis nibh.",
		},
	];
	return (
		<div key={props.key}>
				<InfoTab
					tittle='Human Resources'
					textButton='+ Add Entry'
					onClick={handleAddNewJob}
					columns={notesDataTable()}
					rows={rowHR}
					toolBar={false}
					footer={false}
				/>
			<Divider sx={{ marginTop: "10px", marginBottom: "20px", borderWidth: "medium" }} color={mainColor} />
			<InfoTab
				tittle='Client/Project'
				textButton='+ Add Entry'
				onClick={handleAddNewJob}
				columns={notesDataTable()}
				rows={rowClient}
				toolBar={false}
				footer={false}
			/>
			<Divider sx={{ marginTop: "10px", marginBottom: "20px", borderWidth: "medium" }} color={mainColor} />
			<InfoTab
				tittle='Career Path'
				textButton='+ Add Entry'
				onClick={handleAddNewJob}
				columns={notesDataTable()}
				rows={rowPath}
				toolBar={false}
				footer={false}
			/>
			<Divider sx={{ marginTop: "10px", borderWidth: "medium" }} color={mainColor} />
		</div>
	);
}
