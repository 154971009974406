import { FormControl, MenuItem, Select } from "@mui/material";

interface ISelectOperators {
	value: string;
	onChange: (event: any) => void;
	options: string[];
}
export function SelectOperators({ value, onChange, options }: ISelectOperators) {
	return (
		<FormControl>
			<Select
				value={value}
				onChange={onChange}
				sx={{
					height: "30px",
					width: "100%",
				}}
			>
				{options.map((optionValue: string, index) => {
					return (
						<MenuItem value={optionValue} key={`${optionValue}-${index}`}>
							{optionValue}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
