import { tableColumnHeaderColor, tableRowColor } from "src/constants/colors";
import tw from "twin.macro";

export const styles = {
    tableContainer: {
		width: "90%",
		margin: "auto",
	},
	table: {
		"& .MuiDataGrid-columnHeader": {
			background: tableColumnHeaderColor,
		},
		"& .MuiDataGrid-row": {
			background: tableRowColor,
		},
		"& .MuiDataGrid-toolbarContainer": {
			padding: "10px",
		},
	},
};

export const TableToolBoxContainer = tw.div`
    flex
    justify-around
    items-center
    mb-4
    px-4
    rounded-t-md
    bg-gray-50
    flex-wrap
    `;

export const TableToolBoxContainerGroup = tw.div`
    sm:(flex
    space-x-3
    flex-wrap)
    `;
