import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "../../Button/Button";
import INotificationContainerProps from "./INotificationContainer";
import { styles } from "./NotificationContainerStyleComponents";

export function NotificationContainer({ children, handleClose, handleDashboard }: INotificationContainerProps) {
	const { notificationText } = styles;
	return (
		<Paper sx={{ minWidth: "23rem" }}>
			<Stack padding={1.5}>
				<Grid container spacing={2}>
					<Grid xs={10}>
						<Typography variant='body1' sx={notificationText}>
							Notifications
						</Typography>
					</Grid>
					<Grid xs={2}>
						<IconButton onClick={handleClose}>
							<CloseOutlinedIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Divider />
				<Stack>{children}</Stack>
				<Stack display='flex' justifyContent='flex-end' alignItems='center' direction='row'>
					<Button variant='contained' endIcon={<LaunchIcon />} rounded onClick={handleDashboard}>
						DASHBOARD
					</Button>
				</Stack>
			</Stack>
		</Paper>
	);
}
