import { CssBaseline, ThemeProvider } from "@mui/material";
import { ProviderTree } from "@utils/index";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { LoadingContextProvider } from "./contexts/LoadingStateContext";
import "./index.css";
import { Navigation } from "./routes/routes";
import theme from "./styles/Themes";
import { DynamicViewTagContextProvider } from "./v2/contexts";
import { SidebarProvider } from "./v2/contexts/SidebardContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const Providers = ProviderTree([
	[ThemeProvider, { theme }],
	[LoadingContextProvider],
	[AuthProvider],
	[DynamicViewTagContextProvider],
	[SidebarProvider],
]);

root.render(
	<StrictMode>
		<BrowserRouter>
			<Providers>
				<CssBaseline />
				<Navigation />
			</Providers>
		</BrowserRouter>
	</StrictMode>
);
