import { NavBar } from "src/components/NavBar/NavBar";
import { useContext } from "react";
import LoadingContext from "src/contexts/LoadingStateContext";
import { ILoadingContextState } from "src/contexts/IContexts";
import Loading from "src/components/Loading/Loading";
import { TransformForm } from "src/v2/components/TransformForm/TransformForm";
import { ICandidateTransformForm } from "src/v2/components/TransformForm/ITransformForm";
import { MainContainer, TitleContainer } from "./CandidateToEmployeeStyles";

export function CandidateToEmployee() {
  const { loading } = useContext(LoadingContext) as ILoadingContextState;
  const candidateExample: ICandidateTransformForm[] = [
    {
      first_name: "Andrea",
      last_name: "Jones",
      ioet_id: "",
      img: "https://img.freepik.com/free-photo/portrait-young-asian-lady-with-positive-expression-smile-broadly-dressed-casual-clothing-yellow-wall-happy-adorable-glad-woman-rejoices-success-facial-expression-concept_7861-3306.jpg?size=626&ext=jpg&ga=GA1.1.1412446893.1705276800&semt=ais",
    },
  ];

  return (
    <>
      {loading && <Loading />}

      <NavBar />
      <MainContainer aria-label="Main Form">
        <TitleContainer aria-label="Title Text">
          <h1 className='ioet-orange'>Candidate Page</h1>
        </TitleContainer>
        {candidateExample.map((candidate, index) => (
          <div key={index}>
            <TransformForm candidate={candidate} index={index + 1} />
          </div>
        ))}
      </MainContainer>
    </>
  );
}
