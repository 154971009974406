import { CircularProgress, Autocomplete as MuiAutocomplete, TextField } from "@mui/material";
import { AutocompleteComponent } from "./types";

export const Autocomplete: AutocompleteComponent = (props) => {
	const { inputProps, ...rest } = props;

	return (
		<MuiAutocomplete
			sx={{ width: 300 }}
			renderInput={(params) => (
				<TextField
					{...params}
					label='Select'
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{rest.loading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					{...inputProps}
				/>
			)}
			{...rest}
		/>
	);
};
