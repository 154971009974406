import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import Select from "src/v2/components/atoms/Select/Select";
import { DataGrid } from "@mui/x-data-grid";
import { PRIMARY_COLOR } from "src/constants/colors";
import { styles } from "src/v2/components/organisms/Table/TableStyleComponents";
import { Dict } from "src/v2/models";
import { filterColumns } from "src/utils/filterColumns";
import Button from "src/v2/components/atoms/Button/Button";
import React from "react";
import Icon from "src/v2/components/atoms/Icon/Icon";

export function CompensationHistorySection(compensationInformation: { compensationInformation: Dict[] }) {
	const { compensationInformation: personCompensationData } = compensationInformation;
	const dataIconColumnGap = "1%";
	const { table } = styles;
	const countryOptions = [
		{ label: "ioet Ecuador", value: "ioet Ecuador" },
		{ label: "ioet Argentina", value: "ioet Argentina" },
		{ label: "ioet Colombia", value: "ioet Colombia" },
	];
	const headerCompensationMap: { [key: string]: string } = {
		effectiveDate: "Effective Date",
		payFrequency: "Pay Schedule",
		payRate: "Salary",
		changeReason: "Change Reason",
		comment: "Comment",
	};
	const personCompensationFilter = filterColumns(personCompensationData, headerCompensationMap);
	const columns = React.useMemo(() => {
		const keys = personCompensationFilter.length > 0 ? Object.keys(personCompensationFilter[0]) : [];
		const existingColumns = keys.map((key) => ({
			field: key,
			headerName: headerCompensationMap[key],
			minWidth: 250,
		}));
		const actionColumn = {
			field: "action",
			headerName: "Action",
			minWidth: 250,
			renderCell: () => <Icon icon='pencil' />,
		};
		return [...existingColumns, actionColumn];
	}, [personCompensationFilter]);

	const rows = React.useMemo(
		() =>
			personCompensationData.map((item, index) => ({
				id: index,
				...item,
			})),
		[personCompensationFilter]
	);
	return (
		<div style={{ height: "100vh" }}>
			<div style={{ paddingTop: "2%", paddingBottom: "3%", maxWidth: "805px" }}>
				<DataIcon
					iconName={{ icon: "compensation" }}
					text={{
						children: "Compensation History",
						variant: "h6",
						sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
					}}
					columnGap={dataIconColumnGap}
				/>
			</div>
			<Select label='Country' value='ioet Ecuador' options={countryOptions} sx={{ width: "235px" }} />
			<div role='table' style={{ paddingTop: "2%", paddingBottom: "3%", width: "90%" }}>
				<div style={{ display: "flex", justifyContent: "end", marginBottom: "2%" }}>
					<Button text='+ Add Entry' variant='outlined' />
				</div>
				<DataGrid sx={{ ...table }} columns={columns} rows={rows} hideFooter />
				<div style={{ display: "flex", justifyContent: "end", marginTop: "2%" }}>
					<Button text='Show more...' variant='outlined' />
				</div>
			</div>
		</div>
	);
}
