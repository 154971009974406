import { Typography } from "../Typography";
import { Icon } from "../Icon";
import { UserRow } from "./types";

function UserInformationRow({ icon= "default", text, iconColor = "#FD521D", textWeight = "bold" }: UserRow) {
	return (
		<div className={`font-${textWeight} flex mt-2`}>
			<Icon icon={icon} style={{ color: `${iconColor}`, marginRight: "0.7rem" }} />
			<Typography sx={{ fontWeight: `${textWeight}` }}>{text}</Typography>
		</div>
	);
}

export default UserInformationRow;
