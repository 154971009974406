import Icon from "src/v2/components/atoms/Icon/Icon";
import { IIconGroup } from "./types";

function IconGroup({ icons }: IIconGroup) {
	return (
		<div style={{ display: "flex", flexWrap: "wrap", columnGap: "5%" }}>
			{icons.map((item, index) => (
				<div key={`key-${index}`}>
					<Icon icon={item.icon} />
				</div>
			))}
		</div>
	);
}

export default IconGroup;
