import * as yup from "yup";

export const transformcandidateInformationSchema = yup
	.object({
		first_name: yup.string().required("This field is required"),
		last_name: yup.string().required("This field is required"),
		ioet_id: yup.string().required("This field is required"),
		ioet_email: yup.string().email("Email format is not valid").required("This field is required"),
		preferred_name: yup.string(),
		start_date: yup.string(),
	})
	.required();
