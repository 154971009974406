import { Dict } from "src/v2/models/dynamicModel.model";

export const filterColumns = (data: Dict[], headerMap: { [key: string]: string }) => {
	const filter = data.map((obj) => {
		const filteredObj = { ...obj };
		Object.keys(filteredObj).forEach((key) => {
			if (!headerMap[key]) {
				delete filteredObj[key];
			}
		});
		return filteredObj;
	});
	return filter;
};
