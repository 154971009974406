import Button from "@mui/material/Button";
import { Background, ModalContainer, Title } from "./TransformModalStyles";

interface ITModal {
  text: string;
  IhandleSubmit: () => void;
  onClose: () => void;
}

export function TransformModal({ text, IhandleSubmit, onClose }: ITModal) {
  return (
    <Background onClick={onClose}>
      <ModalContainer aria-label="Modal Background">
        <Title>{text}</Title>
        <div className='flex flex-col  lg:flex-row '>
          <Button
            onClick={IhandleSubmit}
            variant='contained'
            color='success'
            className='w-40'
            aria-label="Submit Button"
          >
            Submit
          </Button>
        </div>
      </ModalContainer>
    </Background>
  );
}
