import { BACKEND_URL, AUTH_APP_NAME, AUTH_URL } from "./authConstant";

// V1
const LOGOUT_URL = `${BACKEND_URL}/logout/`;
const LOGIN_URL = `${AUTH_URL}/authn/login/${AUTH_APP_NAME}/`;
const PEOPLE_LOGIN_URL = "/login/";
const PEOPLE_BASE_URL = "/";
const PEOPLE_HOME_URL = "/home/";
const PEOPLE_API_LIST_EMPLOYEE = "/list_employees/";
const PEOPLE_API_ADD_EMPLOYEE = "/add_employee/";
const PEOPLE_API_EDIT_EMPLOYEE = "/edit_employee/";
const PEOPLE_API_CALL_HYDRATION = "/database_hydration/";
const PEOPLE_EMPLOYEE_PROFILE_URL = "/profile_employee/";
const PEOPLE_EMPLOYEE_PROFILE_ID_URL = "/profile_employee/:employeeId";
const PEOPLE_API_GET_EMPLOYEE = "/employee/";
const PEOPLE_ADD_CANDIDATE_URL = "/add_candidate/";
const PEOPLE_GENERATE_IOET_ID = "/employee/ioet-id/";
const PEOPLE_CREATE_CANDIDATE = "/candidate/";
const PEOPLE_CANDIDATE_TRANSFORMATION = "/transform_candidate";

// V2
const PEOPLE_BFF_PERSON = "/person/";
const PEOPLE_PERSON_URL = "/person/:personId";

export {
  // V1
  LOGOUT_URL,
  LOGIN_URL,
  PEOPLE_LOGIN_URL,
  PEOPLE_BASE_URL,
  PEOPLE_API_LIST_EMPLOYEE,
  PEOPLE_API_ADD_EMPLOYEE,
  PEOPLE_API_EDIT_EMPLOYEE,
  PEOPLE_API_CALL_HYDRATION,
  PEOPLE_HOME_URL,
  PEOPLE_EMPLOYEE_PROFILE_URL,
  PEOPLE_EMPLOYEE_PROFILE_ID_URL,
  PEOPLE_API_GET_EMPLOYEE,
  PEOPLE_ADD_CANDIDATE_URL,
  PEOPLE_GENERATE_IOET_ID,
  PEOPLE_CREATE_CANDIDATE,
  // V2
  PEOPLE_BFF_PERSON,
  PEOPLE_PERSON_URL,
  PEOPLE_CANDIDATE_TRANSFORMATION,
};
