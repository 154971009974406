import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import React, { useEffect, useState } from "react";
import Loading from "src/components/Loading/Loading";
import { transformDataToTableData } from "../adapters/table.adapter";
import { DropdownOptions } from "../components/DropdownOptions/DropdownOptions";
import DynamicForm from "../components/DynamicForm/DynamicForm";
import { PropertyInput } from "../components/Input/PropertyInput/PropertyInput";
import BasicModal from "../components/Modal/BasicModal";
import { Table } from "../components/organisms/Table/Table";
import useColumns from "../hooks/useColumns";
import useDynamicViewTagContext from "../hooks/useDynamicViewModelContext";
import { useSidebar } from "../hooks/useSidebar";
import { Section, SectionsMap } from "./IListPeoplePage";
import { columnsHiddenByDefault } from "../services/dataDefinitionSchema.service";

export function ListPeoplePage() {
	const { RawPeopleData, TimeNotificationData, DataDefinitionSchema, initialize } = useDynamicViewTagContext();
	const [peopleTableData, setPeopleTableData] = useState<any>(null);
	const [modalSections, setModalSections] = useState<JSX.Element[]>([]);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const properties = { displayCheckbox: true, hidePagination: false };
	const { setShowSidebar } = useSidebar();
	const columns = useColumns();
	useEffect(() => {
		const initializeData = async () => {
			await initialize("table_page_id");
		};

		initializeData();
		setShowSidebar(true);
	}, []);

	useEffect(() => {
		if (!RawPeopleData || !DataDefinitionSchema || !TimeNotificationData) return;
		const tableData = transformDataToTableData(RawPeopleData, DataDefinitionSchema);
		setPeopleTableData(tableData);
	}, [RawPeopleData, DataDefinitionSchema, TimeNotificationData]);

	const sections: SectionsMap = {
		options: <DropdownOptions />,
		propertyName: <PropertyInput />,
		profileLocation: <div>Some one</div>,
		propertyDisplay: <div>Disabled</div>,
	};

	const handleClick = (title: string, sectionNames: Section[] = []) => {
		const mappedSections = sectionNames.map((sectionName, index) =>
			sectionName === "propertyName" ? (
				<div key={index}>{React.cloneElement(sections[sectionName], { label: title })}</div>
			) : (
				<div key={index}>{sections[sectionName]}</div>
			)
		);
		setModalSections(mappedSections);
		handleOpen();
	};

	const handleSubmit = () => {
		// TODO: Implement handleSubmit function
		return true;
	};

	const items = [
		{
			icon: <ImportContactsIcon />,
			title: "Text",
			handleClick: () => handleClick("Text", ["propertyName", "profileLocation", "propertyDisplay"]),
		},
		{
			icon: <ArrowDropDownCircleOutlinedIcon />,
			title: "Dropdown",
			handleClick: () => handleClick("Dropdown", ["propertyName", "options"]),
		},
		{
			icon: <CalendarTodayOutlinedIcon />,
			title: "Date",
			handleClick: () => handleClick("Date", ["profileLocation", "propertyDisplay"]),
		},
		{
			icon: <TagOutlinedIcon />,
			title: "Number",
			handleClick: () => handleClick("Number", ["propertyName"]),
		},
		{
			icon: <CheckBoxOutlinedIcon />,
			title: "Checkbox",
			handleClick: () => handleClick("Checkbox", ["propertyName", "options"]),
		},
	];

	if (!peopleTableData) return <Loading />;

	return (
		<div style={{ width: "100%" }}>
			{!peopleTableData ? (
				<Loading />
			) : (
				<div>
					{/* <NavBar /> */}
					<BasicModal open={open} handleClose={handleClose}>
						<DynamicForm handleSubmit={handleSubmit}>{modalSections}</DynamicForm>
					</BasicModal>
					<Table
						columns={columns!}
						rowData={peopleTableData}
						displayCheckbox={properties.displayCheckbox}
						hidePagination={properties.hidePagination}
						pageSize={[10, 25, 50, 100]}
						toolBoxComponents={[]}
						sxTableContainer={{
							maxWidth: "calc(100vw - 140px)",
							margin: "auto",
						}}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 100, page: 0 },
							},
							columns: {
								columnVisibilityModel: columnsHiddenByDefault,
							},
						}}
					/>
				</div>
			)}
		</div>
	);
}
