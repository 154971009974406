import { Route, Routes } from "react-router-dom";
import Layout from "src/v2/components/templates/Layout/Layout";
import { PersonProfile } from "src/v2/pages/PersonProfile/PersonProfile";
import { ListPeoplePage } from "src/v2/pages/listPeoplePage";
import RequiredAdmin from "../components/Auth/requireAdmin";
import LoginStatusContainer from "../components/LoginStatusContainer/LoginStatusContainer";
import {
	PEOPLE_ADD_CANDIDATE_URL,
	PEOPLE_BASE_URL,
	PEOPLE_CANDIDATE_TRANSFORMATION,
	PEOPLE_EMPLOYEE_PROFILE_ID_URL,
	PEOPLE_HOME_URL,
	PEOPLE_LOGIN_URL,
	PEOPLE_PERSON_URL,
} from "../constants/urlConstants";
import { CandidatePage } from "../pages/CandidatePage/CandidatePage";
import { EmployeeProfile } from "../pages/EmployeeProfile/EmployeeProfile";
import { HomeScreen } from "../pages/HomeScreen/HomeScreen";
import { CandidateToEmployee } from "../v2/pages/CandidateToEmployee/CandidateToEmployee";

export function Navigation() {
	return (
		<Routes>
			<Route path={PEOPLE_LOGIN_URL} element={<LoginStatusContainer />} />
			<Route path={PEOPLE_BASE_URL} element={<RequiredAdmin />}>
				<Route path='/' element={<Layout />}>
					<Route path={PEOPLE_ADD_CANDIDATE_URL} element={<CandidatePage />} />
					<Route index path={PEOPLE_BASE_URL} element={<ListPeoplePage />} />
					<Route path={PEOPLE_HOME_URL} element={<HomeScreen />} />
					<Route path={PEOPLE_EMPLOYEE_PROFILE_ID_URL} element={<EmployeeProfile />} />
					<Route path={PEOPLE_CANDIDATE_TRANSFORMATION} element={<CandidateToEmployee />} />
					<Route path={PEOPLE_PERSON_URL} element={<PersonProfile />} />
				</Route>
			</Route>
			<Route path='*' element={<h1>Page Not Found </h1>} />
		</Routes>
	);
}
