import React from "react";
import { Button as MuiButton } from "@mui/material";
import { Icon } from "src/v2/components/atoms/Icon";
import { IButtonProps } from "./types";
import { Typography } from "../Typography";

const Button: React.FC<IButtonProps> = ({
	variant = "contained",
	color = "primary",
	size = "medium",
	iconPosition,
	icon,
	disabled = false,
	disableElevation = false,
	text,
	onClick,
	iconStyle,
}) => {
	return (
		<MuiButton
			variant={variant ?? "contained"}
			color={color ?? "primary"}
			size={size ?? "medium"}
			startIcon={iconPosition === "left" ? <Icon icon={icon || "default"} style={iconStyle} /> : null}
			endIcon={iconPosition === "right" ? <Icon icon={icon || "default"} style={iconStyle} /> : null}
			disabled={disabled ?? false}
			disableElevation={disableElevation ?? false}
			onClick={onClick}
		>
			<Typography variant='button' >{text}</Typography>
		</MuiButton>
	);
};

export default Button;
