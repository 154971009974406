import { createTheme } from "@mui/material/styles";
import { PRIMARY_COLOR, TEXTFIELD_BACKGROUND_COLOR } from "src/constants/colors";

export const themePeopleApp = createTheme({
	palette: {
		primary: {
			main: PRIMARY_COLOR,
		},
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					background: TEXTFIELD_BACKGROUND_COLOR,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					background: TEXTFIELD_BACKGROUND_COLOR,
				},
			},
		},
	},
});
