import React, { memo, useEffect, useState } from "react";

import { Box, Snackbar } from "@mui/material";
import {
	DataGrid,
	GridCell,
	GridCellModes,
	GridCellModesModel,
	GridColumnHeaders,
	GridEventListener,
	GridRow,
	GridRowId,
	useGridApiRef,
} from "@mui/x-data-grid";
import { updateOnePersonData } from "src/v2/services/peopleData.service";
import { generateIncrementingNumbers } from "../../../../data/utils/generators";
import { TableProps } from "./types";
import { styles, TableToolBoxContainer, TableToolBoxContainerGroup } from "./TableStyleComponents";

import { CustomSelectedCellProps, CustomToolBar } from "../../CustomToolBar/CustomToolBar";
import { Alert, IAlertProps } from "../../atoms/Alert";

interface AlertProps {
	open: boolean;
	data: IAlertProps;
}

const INIT_ALERT_DATA = {
	open: false,
	data: {
		textAlert: "",
	},
};

const memoizedRow = memo(GridRow);
const memoizedCell = memo(GridCell);
const MemoizedColumnHeaders = memo(GridColumnHeaders);

export function Table<T>(props: TableProps<T>) {
	const {
		columns,
		rowData,
		toolBoxComponents,
		hidePagination = true,
		displayCheckbox = false,
		pageSize = [25, 50, 100],
		initialState = {},
		sxTableContainer = {
			maxWidth: "90%",
			margin: "auto",
		},
	} = props;

	const { table } = styles;

	const rowDataMemo = React.useMemo(() => rowData, [rowData]);
	const apiRef = useGridApiRef();
	const [rowsData, setRowsData] = useState<T[]>(rowData);
	const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
	const [selectedCell, setSelectedCell] = useState<CustomSelectedCellProps | null>(null);
	const [alertData, setAlertData] = React.useState<AlertProps>(INIT_ALERT_DATA);

	const isCellEditingMemo = React.useMemo(() => {
		return (
			Object.keys(cellModesModel).length !== 0 &&
			selectedCell &&
			cellModesModel[selectedCell.id]?.[selectedCell.field].mode === GridCellModes.Edit
		);
	}, [selectedCell, cellModesModel]);

	const handleVariables = (id: GridRowId, field: string) => {
		setCellModesModel({
			[id]: {
				[field]: {
					mode: GridCellModes.Edit,
				},
			},
		});
		setSelectedCell({ id, field });
	};

	const handleOnCellEditStart = React.useCallback<GridEventListener<"cellEditStart">>(
		(params, event) => {
			if (isCellEditingMemo) {
				alert("There is a cell being edited, please save changes before continuing"); // eslint-disable-line no-alert
				apiRef.current.setCellFocus(selectedCell!.id, selectedCell!.field);
				apiRef.current.setRowSelectionModel([selectedCell!.id]);
			} else {
				handleVariables(params.id, params.field);
			}
			event.defaultMuiPrevented = true;
		},
		[isCellEditingMemo]
	);

	const handleProcessRowUpdate = async (updateData: any) => {
		const { id, field } = selectedCell ?? { id: "", field: "" };
		try {
			const requestData = {
				id: updateData["id"],
				ioetId: updateData["ioetId"],
				ioetEmail: updateData["ioetEmail"],
				[field]: updateData[field],
				...(field === "name" && { lastName: updateData["lastName"] }),
				...(field === "lastName" && { name: updateData["name"] }),
			};
			await updateOnePersonData(requestData);
			setAlertData({
				...alertData,
				open: true,
				data: { textAlertTitle: "Success", textAlert: "Data was successfully updated.", severity: "success" },
			});
		} catch (error) {
			apiRef.current.setCellFocus(id, field);
			apiRef.current.setRowSelectionModel([id]);
			handleVariables(id, field);
			setAlertData({
				...alertData,
				open: true,
				data: { textAlertTitle: "Failure", textAlert: "Failure to update data.", severity: "error" },
			});
		}
		return updateData;
	};

	const handleCloseAlert = () => {
		setAlertData({ ...alertData, open: false });
	};

	const handleBeforeUnload = React.useCallback(
		(event: any) => {
			if (isCellEditingMemo) {
				event.returnValue = "Are you sure you want to leave?";
			}
		},
		[cellModesModel]
	);

	useEffect(() => {
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [handleBeforeUnload]);

	return (
		<>
			<Snackbar
				open={alertData.open}
				autoHideDuration={4000}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				onClose={handleCloseAlert}
			>
				<Alert {...alertData.data} onClose={handleCloseAlert} />
			</Snackbar>
			<TableToolBoxContainer>
				<TableToolBoxContainerGroup>{toolBoxComponents}</TableToolBoxContainerGroup>
			</TableToolBoxContainer>
			{rowDataMemo?.length > 0 && (
				<Box component='div' sx={sxTableContainer}>
					<DataGrid
						sx={table}
						apiRef={apiRef}
						processRowUpdate={handleProcessRowUpdate}
						cellModesModel={cellModesModel}
						onCellEditStart={handleOnCellEditStart}
						{...toolBoxComponents}
						getRowHeight={() => "auto"}
						getRowId={(row) => row?.id ?? generateIncrementingNumbers().next().value}
						rows={rowsData}
						columns={columns}
						slots={{
							toolbar: CustomToolBar,
							row: memoizedRow,
							cell: memoizedCell,
							columnHeaders: MemoizedColumnHeaders,
						}}
						checkboxSelection={displayCheckbox}
						slotProps={{
							toolbar: {
								columns,
								currentRows: rowData,
								filteredRows: rowsData,
								setFilterRow: setRowsData,
								setCellModesModel,
								selectedCell,
								isCellEditingMemo,
							},
						}}
						pageSizeOptions={pageSize}
						hideFooterPagination={hidePagination}
						initialState={initialState}
					/>
				</Box>
			)}
		</>
	);
}
