import { Typography } from "src/v2/components/atoms/Typography";
import { ITypographyGroup } from "./types";

function TypographyGroup(typographyList: ITypographyGroup) {
	const { typographyList: list } = typographyList;
	return (
		<div>
			{list.map((item, index) => (
				<div key={`typography-${index}`}>
					<Typography variant={item.variant} color={item.color ? item.color : "primary"}>
						{item.children}
					</Typography>
				</div>
			))}
		</div>
	);
}

export default TypographyGroup;
