// eslint-disable-next-line import/no-cycle
import { ColumnDefinitionFields } from ".";

export enum ViewTag {
    simple_string = "simple_string",
    boolean = "checkbox",
    email = "email",
    date = "date",
    array = "array",
    object_modal = "object_modal",
    object = "object",
    details_button = "details_button",
    ACTION = "ACTION",
    select = "select",
    chip = "chip",
}

export interface IDynamicViewTagProps {
    value: any;
    frozen: boolean;
    label: string;
}
export type DynamicViewTagComponent = (props: IDynamicViewTagProps) => JSX.Element;
export type ViewTagComponentMapping = Record<ColumnDefinitionFields["view_tag"], DynamicViewTagComponent>;
