import { Box, Popover, TextField } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { SelectOperators } from "./SelectOperators";
import { Button } from "../Button/Button";
import { IPopoverFilterProps } from "./IFilter";
import { OPERATOR_OPTIONS } from "./utils";

export default function PopoverFilter({
	filter,
	filterAttributes,
	anchorEl,
	handleClose,
	handleOperator,
	handleInput,
	handleDeleteFilter,
	operator,
}: IPopoverFilterProps) {
	return (
		<Popover
			id={`simple-popover-${filter}`}
			open={filterAttributes[filter].openPopover}
			anchorEl={anchorEl}
			onClose={() => handleClose(filter)}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
		>
			<Box sx={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
				<SelectOperators value={operator} onChange={handleOperator} options={OPERATOR_OPTIONS} />

				<Button onClick={() => handleDeleteFilter(filter)}>
					<DeleteForeverIcon />
				</Button>
			</Box>
			<Box sx={{ padding: "5px" }}>
				<TextField size='small' name={filter} onChange={handleInput} value={filterAttributes[filter].value} />
			</Box>
		</Popover>
	);
}
