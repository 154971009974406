import { useState } from "react";
import { Dict } from "../models";

const usePersonInformation = () => {
	const [personInformationMock, setPersonInformationMock] = useState<Dict | null>(null);
	const initialize = (personId: string) => {
		// TODO: "Implement service to get data from the bff"
		setPersonInformationMock({
			uuid: personId,
			name: "Luissss",
			lastName: "Abrie",
			ioetId: "labrie001",
			ioetEmail: "luis.abrie@ioet.com",
			notionId: "44a9f966-2436-44c4-b7d6-0e547699b1d4",
			isNewHire: false,
			gender: "MALE",
			maritalStatus: "single",
			dateOfBirth: "2020-01-01",
			age: "20",
			timeWork: "2y-3m-5d",
			countryOfBirth: "Ecuador",
			city: "Quito",
			ssn: "987654321",
			cedula: "11111111",
			ruc: "123456789",
			address1: "address1",
			address2: "address2",
			province: "state",
			zipCode: "",
			githubId: "",
			linkedIn: "https://ec.linkedin.com/in/aaaaaa",
			twitterFeed: "twitter.com",
			facebook: "",
			tShirtSize: "Medium",
			startDate: "2023-06-24",
			endDate: "",
			promisedReviewDate: "",
			country: "Colombia",
			mobilePhone: "+593 987654321",
			homeEmail: "aaaaaa@gmail.com",
			office: "INTERNATIONAL",
			contractType: "FULL_TIME",
			level: "intern",
			internalApps: "",
			workPhone: "",
			workPhoneExtension: "",
			homePhone: "",
			status: [
				{
					name: "shadow available",
				},
				{
					name: "internal app",
				},
			],
			workTitles: [
				{
					name: "software developer",
				},
			],
			bankAccount: [
				{
					accountNumber: "1200-123-550",
					swiftBic: "SUPER-SWIFT-EC",
					beneficiaryName: "Luis Abrie",
					currency: "USD",
					weight: "100",
					financialEntity: "",
					financialEntityCountry: "",
					typeOfPayment: "",
				},
			],
			businessPtos: "",
			education: [
				{
					id: "482d13fc-a599-11ee-9ee4-0eb9c21be889",
					specialization: "System Engineer",
					gpa: "4.0",
					startDate: "2007-01-01",
					endDate: "2011-01-02",
					institution: "Universidad",
					degreeName: "Information Technology",
				},
			],
			jobInformation: [
				{
					id: "482e9240-a599-11ee-9ee4-0eb9c21be889",
					effectiveDate: "2015-05-05",
					location: "International",
					division: "EaaS",
					department: "Software Engineering",
					jobTitle: "Member of Technical Staff",
					supervisorId: "",
					supervisorName: "Jhon",
					supervisorLastName: "Doe",
					employmentStatus: "employee",
					contractType: "full time",
					ioetLevel: "mid level",
					staffingStatus: "assigned",
					internalApp: "people",
					internalRole: "dev",
				},
				{
					id: "482e9240-a599-11ee-9ee4-0eb9c21be889",
					effectiveDate: "2015-05-05",
					location: "International",
					division: "EaaS",
					department: "Software Engineering",
					jobTitle: "Member of Technical Staff",
					supervisorId: "",
					supervisorName: "Bob",
					supervisorLastName: "Mclister",
				},
			],
			employmentStatus: [
				{
					id: "482e00f0-a599-11ee-9ee4-0eb9c21be889",
					statusDate: "2023-06-24",
					statusCategory: "EMPLOYEE",
					comment: "Employee status created from notion data",
					originalHireDate: "",
					payGroup: "",
				},
			],
			compensation: [
				{
					id: "482ca656-a599-11ee-9ee4-0eb9c21be889",
					effectiveDate: "2020-01-01",
					payRate: "1000",
					overtimeRate: "",
					overtimeCurrency: "USD",
					changeReason: "starting package",
					comment: "current ioet Ecuador S.A.",
					transactionCurrency: "USD",
					payType: "Salary",
					payFrequency: "Monthly",
				},
			],
			emergencyContact: [
				{
					name: "Meagan Garza",
					relationship: "Spouse",
					primaryContact: true,
					workPhone: "(851)149-0105x02950",
					phoneExtension: null,
					mobilePhone: "6669669560",
					homePhone: "774.958.9469x8626",
					email: "bonillabrooke@example.net",
					address1: "165 Rodney Freeway",
					address2: "4369 Martin Station",
					city: "East Samanthaport",
					province: "New Mexico",
					zipCode: "53699",
				},
			],
			bambooData: [
				{
					effectiveDate: "01/01/2023",
					location: "Quito",
					departament: "EAAS",
					jobTittle: "Dev",
					reportsTo: "Mari Zarraga",
				},
			],
			humanResources: [{ date: "27/01/2023", author: "User field", category: "16", note: "Note filed" }],
		});
	};
	return { personInformationMock, initialize };
};

export default usePersonInformation;
