import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { IoetIcon, IoetIcons } from "src/v2/icons";
import Icon from "src/v2/components/atoms/Icon/Icon";
import { ITextFieldProps } from "./types";

const TextField: React.FC<ITextFieldProps> = ({
	id,
	label,
	type,
	hug = "",
	variant = "standard",
	value,
	onChange,
	sx,
	size,
	fullWidth = true,
	multiline = false,
	maxRows = 3,
	minRows = 1,
	read = true
}) => {
	return (
		<MuiTextField
			fullWidth={fullWidth}
			id={id}
			label={label}
			type={type}
			value={value}
			onChange={onChange}
			variant={variant}
			sx={sx}
			size={size}
			multiline={multiline}
			maxRows={maxRows}
			minRows={minRows}
			InputProps={
				{
					startAdornment: hug in IoetIcons ? <Icon icon={hug as IoetIcon}/> : "",
					readOnly: read
				}
			}
		/>
	);
};

export default TextField;
