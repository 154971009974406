import React, { useState } from "react"
import { Divider } from "@mui/material";
import { DynamicFormProps } from "./IDynamicForm"

export default function DynamicForm({ children, handleSubmit }: DynamicFormProps) {

  const [ formData, setFormData ] = useState({});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputId = event.currentTarget.id
    const inputValue = event.currentTarget.value
    setFormData({
      ...formData,
      [inputId]: inputValue
    })
  }

  return (
    <form onSubmit={(event) => handleSubmit(event, formData)}>
      {children.map((child, index) => (
        <div key={index}>
          {React.cloneElement(child, { key: index, onChange: handleInputChange })}
          {index < children.length - 1 && <Divider sx={{ marginY: 1 }} />}
        </div>
      ))}
    </form>
  )
}
