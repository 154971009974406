import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import Select from "src/v2/components/atoms/Select/Select";
import { TextField } from "src/v2/components/atoms/TextField";
import DatePicker from "src/v2/components/atoms/DatePicker/DatePicker";
import dayjs from "dayjs";
import { Dict } from "src/v2/models";
import { PRIMARY_COLOR } from "src/constants/colors";
import { Box } from "@mui/system";

export function BasicInformationSection(personInformation: Dict) {
	const { personInformation: personData } = personInformation;
	const dataIconColumnGap = "1%";
	const countries = [
		{ label: "Ecuador", value: "Ecuador" },
		{ label: "Argentina", value: "Argentina" },
		{ label: "Colombia", value: "Colombia" },
	];

	return (
		<Box
			data-testid='basic-information-container'
			sx={{
				width: {lg: "70%", md:"100%"},
				marginBottom: "3%"
			}}
		>
			<div style={{ paddingBottom: "3%" }}>
				<DataIcon
					iconName={{ icon: "userInformation" }}
					text={{ children: "Basic Information", variant: "h6", sx: { color: PRIMARY_COLOR, fontWeight: "bold" } }}
					columnGap={dataIconColumnGap}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-employeeId'>
				<TextField
					id='employeeId'
					variant='outlined'
					label='Employee ID'
					value={ personData.ioetId ?? "" }
					size='small'
					sx={{ width: { md:"40%"} }}
					onChange={() => {}}
				/>
			</div>
			<Box
				sx={{
					width: {lg: "100%", md: "65%"},
					marginBottom: "2%",
					columnGap: "4%",
					display: {md: "flex", sm:"block"}
				}}
				data-testid='basic-information-names'
			>
				<TextField
					id='nameId'
					variant='outlined'
					label='Name'
					value={ personData.name ?? "" }
					size='small'
					onChange={() => {}}
					sx={{
						marginBottom:{md:"0", sm:"2%" }
					}}
				/>
				<TextField
					id='lastNameid'
					variant='outlined'
					label='Last Name'
					value={ personData.lastName ?? "" }
					size='small'
					onChange={() => {}}
				/>
			</Box>
			<Box
				sx={{
					width: { md: "48%", sm:"50%" },
					marginBottom: "2%",
					columnGap: "5%",
					display: {md: "flex", sm:"block", xs:"block"}
				}}
				data-testid='basic-information-age'
			>
				<DatePicker
					label='Birth date'
					value={dayjs(personData.dateOfBirth)}
					disabled
					slotProps={{
						textField: {
							size: "small",
							sx: {
								width: "100%",
								marginBottom:{ md:"0", sm:"4%" }
							}
						}
					}}
				/>
				<TextField
					id='ageId'
					variant='outlined'
					label='Age'
					value={ personData.age ?? "" }
					size= 'small'
					sx={{ width: { md: "40%" }}}
					onChange={() => {}}
				/>
			</Box>
			<div style={{ marginBottom: "2%"}} data-testid='basic-information-ssn'>
				<TextField
					id='ssnId'
					variant='outlined'
					label='SSN'
					value={ personData.snn ?? "" }
					size='small'
					sx={{ width: { md: "40%" }}}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-numberId'>
				<TextField
					id='idNumerId'
					variant='outlined'
					label='ID number'
					value={ personData.cedula ?? "" }
					size='small'
					sx={{ width: { md: "40%" }}}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-ruc'>
				<TextField
					id='rucId'
					variant='outlined'
					label='RUC'
					value={ personData.ruc ?? "" }
					size='small'
					sx={{ width: { md: "40%" }}}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-github'>
				<TextField
					id='gitHubId'
					variant='outlined'
					label='GitHub'
					value={ personData.githubId ?? "" }
					size='small'
					sx={{ width: { md:"40%"} }}
					onChange={() => {}}
				/>
			</div>
			<Box
				sx={{
					width: "65%",
					marginBottom: "2%",
					columnGap: "5%",
					display: {md: "flex", sm:"block"}
				}}
				data-testid='basic-information-gender'
			>
				<TextField
					id='genderId'
					variant='outlined'
					label='Gender'
					value={ personData.gender ?? "" }
					size='small'
					sx={{
						marginBottom: {md:"0", sm:"2%"}
					}}
					onChange={() => {}}
				/>
				<TextField
					id='maritalStatusId'
					variant='outlined'
					label='Marital Status'
					// TODO marital status should be implemented in the backend
					value={ personData.maritalStatus ?? "" }
					size='small'
					onChange={() => {}}
				/>
			</Box>
			<div style={{ marginBottom: "2%"}} data-testid='basic-information-address'>
				<TextField
					id='addresId'
					variant='outlined'
					label='Address'
					value={personData.address1}
					size='small'
					multiline
					onChange={() => {}}
				/>
			</div>
			<Box
				sx={{
					marginBottom: "2%",
					columnGap: "4%",
					display: {md: "flex", sm:"block", xs:"block"}
				}}
				data-testid='basic-information-city'
			>
				<TextField
					id='cityId'
					variant='outlined'
					label='City'
					value={ personData.city ?? "" }
					size= 'small'
					sx={{
						width: {md: "33%"},
						marginBottom: {md:"0", sm:"2%"}
					}}
					onChange={() => {}}
				/>
				<TextField
					id='stateId'
					variant='outlined'
					label='State'
					value={ personData.province ?? "" }
					size= 'small'
					sx={{
						width: {md: "33%"},
						marginBottom: {md:"0", sm:"2%"}
					}}
					onChange={() => {}}
				/>
				<TextField
					id='zipCodeId'
					variant='outlined'
					label='Zip Code'
					value={ personData.zipCode ?? "" }
					size='small'
					sx={{ width: { md: "33%"}}}
					onChange={() => {}}
				/>
			</Box>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-country'>
				<Select
					label='Country'
					value={personData.country}
					disabled
					options={countries}
					size='small'
					sx={{ width: { md:"40%"} }}
					fullWidth
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-mobileId'>
				<TextField
					id='mobileId'
					variant='outlined'
					label='Mobile'
					value={ personData.mobilePhone ?? "" }
					size='small'
					sx={{ width: { md:"40%"} }}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-homePhone'>
				<TextField
					id='homePhoneId'
					variant='outlined'
					label='Home phone'
					value={ personData.homePhone ?? "" }
					size='small'
					sx={{ width: { md:"40%"} }}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-email'>
				<TextField
					id='emailId'
					variant='outlined'
					label='Email'
					value={ personData.ioetEmail ?? "" }
					size='small'
					sx={{ width: { md:"80%"} }}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%"}} data-testid='basic-information-personalEmail'>
				<TextField
					id='personalEmailId'
					variant='outlined'
					label='Personal email'
					value={ personData.homeEmail ?? "" }
					size='small'
					sx={{ width: { md:"80%"} }}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%"}} data-testid='basic-information-linkedIn'>
				<TextField
					id='linkedinId'
					variant='outlined'
					label='LinkedIn'
					value={ personData.linkedIn ?? "" }
					size='small'
					sx={{ width: { md: "80%" }}}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%"}} data-testid='basic-information-facebook'>
				<TextField
					id='facebookId'
					variant='outlined'
					label='Facebook'
					value={ personData.facebook ?? "" }
					size='small'
					sx={{ width: { md: "80%" }}}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%" }} data-testid='basic-information-twitter'>
				<TextField
					id='twitterId'
					variant='outlined'
					label='Twitter'
					value={ personData.twitterFeed ?? "" }
					size='small'
					sx={{ width: { md: "80%" }}}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "2%"}} data-testid='basic-information-placeBirth'>
				<TextField
					id='placeOfBirthId'
					variant='outlined'
					label='Place of birth'
					value={ personData.countryOfBirth ?? "" }
					size='small'
					sx={{ width: { md:"40%"} }}
					onChange={() => {}}
				/>
			</div>
			<div style={{ marginBottom: "3%" }} data-testid='basic-information-tshirtSize'>
				<Select
					label='T-shirt Size'
					value={personData.tShirtSize}
					disabled
					options={[
						{ label: "Medium", value: "Medium" },
						{ label: "Small", value: "Small" },
						{ label: "Large", value: "Large" }
					]}
					size='small'
					sx={{ width: { md:"40%"} }}
					fullWidth
				/>
			</div>
		</Box>
	);
}
