import { GridColDef } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { Table } from "src/v2/components/organisms/Table/Table";
import { Button } from "src/v2/components/Button/Button";
import { mainColor } from "src/constants/colors";

interface IInformationTab<T> {
	tittle: string;
	textButton: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement> | null) => void | any;
	columns: GridColDef<any, any, any>[];
	rows: T[];
	toolBar: boolean;
	footer: boolean;
	icon?: React.ReactNode;
}

export function InfoTab<T>({ tittle, textButton, onClick, columns, rows, toolBar, footer, icon }: IInformationTab<T>) {
	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "row",
					fontWeight: "bold",
				}}
			>
				{icon}
				<Typography variant='h5' component='h5' color={mainColor}>
					{tittle}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "end",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						boxShadow: 3,
						borderRadius: 2,
						width: "8rem",
						height: "3rem",
						gridColumnStart: 3,
					}}
				>
					<Button type='addEntry' onClick={onClick}>
						{textButton}
					</Button>
				</Box>
			</Box>
			<Box sx={{width:"40vw",display:"flex", justifyContent:"center"}}>
				<Table columns={columns} rowData={rows} customToolBar={toolBar} hideFooter={footer} />
			</Box>
		</div>
	);
}
