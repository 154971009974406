/* eslint-disable react/function-component-definition */
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { List, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ItemButton } from "src/v2/components/atoms/IteamButton";
import { Drawer } from "src/v2/components/molecules/Drawer";
import { useSidebar } from "../../../hooks/useSidebar";
import { ISidebarProps } from "./types";

const Sidebar: React.FC<ISidebarProps> = ({ open }) => {
	const navigate = useNavigate();

	const items = [
		{
			icon: <DashboardIcon sx={{ color: "#999" }} />,
			title: "Dashboard",
		},
		{
			icon: <PeopleAltIcon sx={{ color: "#999999" }} />,
			title: "People",
		},
	];

	const selectionDefault = items[0].title;
	const [selected, setSelected] = useState<string>(selectionDefault);
	const { showSidebar } = useSidebar();

	const handleSideBardSelection = (title: string) => {
		setSelected(title);
		navigate("/");
	};

	useEffect(() => {
		setSelected(selectionDefault);
	}, [selectionDefault]);

	return !showSidebar ? null : (
		<Drawer
			open={open}
			PaperProps={{
				style: {
					marginTop: "80px",
					height: "calc(100vh - 90px)",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					zIndex: "10",
					marginRight: "10px",
				},
			}}
		>
			<List disablePadding>
				{items.map((item) => {
					return (
						<ListItem key={item.title} disablePadding sx={{ display: "block" }}>
							<ItemButton
								open={open}
								title={item.title}
								icon={item.icon}
								onClick={() => handleSideBardSelection(item.title)}
								style={{
									justifyContent: open ? "initial" : "center",
									backgroundColor: `${selected === item.title ? "#e8effe" : "#fff"}`,
									color: `${selected === item.title ? "#568cf8" : "#000"}`,
								}}
								selected={selected === item.title}
							/>
						</ListItem>
					);
				})}
			</List>
		</Drawer>
	);
};

export default Sidebar;
