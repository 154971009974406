import { Person } from "@components/molecules/TimeNotification/ITimeNotification";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";

export function PersonTimeNotification({ notification, disabled = false }: { notification: Person; disabled?: boolean }) {
	const { name, ioetLevel, id } = notification;
	const renderLink = () => {
		if (disabled) {
			return (
				<Typography
					textAlign='start'
					variant='body1'
					sx={{
						userSelect: "none",
						pointerEvents: "none",
					}}
				>
					{name}
				</Typography>
			);
		}
		return (
			<Link to={`/person/${id}`}>
				<Typography
					textAlign='start'
					variant='body1'
					sx={{
						"&:hover": {
							color: "gray",
						},
					}}
				>
					{name}
				</Typography>
			</Link>
		);
	};
	const ioetLevelSx = disabled
		? {
				userSelect: "none",
				pointerEvents: "none",
		  }
		: {};
	return (
		<Grid container direction='row' spacing={2} alignItems='flex-start' padding='0.5rem' sx={{ opacity: disabled ? 0.5 : 1 }}>
			<Grid xs={6}>{renderLink()}</Grid>
			<Grid xs={6}>
				<Typography textAlign='start' variant='body1' sx={ioetLevelSx}>
					{ioetLevel}
				</Typography>
			</Grid>
		</Grid>
	);
}
