/* eslint-disable react/function-component-definition */
import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { NavBar, Sidebar } from "src/v2/components/organisms";

const Layout = () => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<NavBar open={open} setOpen={setOpen} />
			<Box
				sx={{
					display: "flex",
					width: "100%",
					alignItems: "center",
					mt: "80px",
				}}
			>
				<Sidebar open={open} />
				<Outlet />
			</Box>
		</>
	);
};

export default Layout;
