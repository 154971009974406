import { yupResolver } from "@hookform/resolvers/yup";

import { employeeInformationSchema } from "../schemas/employeeInformationSchema";
import { candidateInformationSchema } from "../schemas/candidateInformationSchema";
import { transformcandidateInformationSchema } from "../schemas/transformcandidateInformationSchema";

export default {
  employeeInformationResolver: yupResolver(employeeInformationSchema),
  candidateInformationResolver: yupResolver(candidateInformationSchema),
  transformcandidateInformationSchema: yupResolver(transformcandidateInformationSchema),
};
