import axios from "axios";
import { BACKEND_URL, PEOPLE_BFF_PERSON } from "../config/constants";
import { DynamicModel } from "../models/dynamicModel.model";

function generateMockData(): DynamicModel {
	return {
		values: {
			personList: [
				// { id: "ioet", name: "John Carter", ioetLevel: "ioet level" },
			],
			month: "January",
			notification_number: 0
		},
	};
}
// Obs: The backend handles the data filtering based on role, but the frontend always need to tell it in what view the user is
export async function fetchAllPeopleData(): Promise<DynamicModel[]> {
	const httpClient = axios.create({
		baseURL: BACKEND_URL,
		withCredentials: true,
	});

	const response = await httpClient.get(PEOPLE_BFF_PERSON);
	const people: DynamicModel[] = response.data.map((item: [Object]) => {
		const person = { values: item } as DynamicModel;
		return person;
	});
	return people;
}

export async function updateOnePersonData(newData: any) {
	const httpClient = axios.create({
		baseURL: BACKEND_URL,
		withCredentials: true,
	});
	httpClient.defaults.headers.post["Content-Type"] = "application/json";

	const response = await httpClient.put(PEOPLE_BFF_PERSON, newData);
	return response;
}

export async function getPersonInformation(ioetId: string) {
	const httpClient = axios.create({
		baseURL: BACKEND_URL,
		withCredentials: true,
	});
	httpClient.defaults.headers.get["Content-Type"] = "application/json";

	const response = await httpClient.get(`${PEOPLE_BFF_PERSON}${ioetId}`);
	return response.data;
}

export async function fetchAllTimeNotifications(): Promise<DynamicModel> {
	const people = generateMockData();
	return Promise.resolve(people);
}
