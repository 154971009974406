import { Button as ButtonMUI } from "@mui/material";
import { IButtonProps } from "./IButton";
import { styles } from "./StyleButton";

export function Button({
	children,
	onClick,
	type = "button",
	variant = "text",
	startIcon,
	color,
	endIcon,
	rounded,
}: IButtonProps) {
	const actualStyles = styles[type];
	const buttonType = type === "submit" ? type : undefined;
	return (
		<ButtonMUI
			onClick={onClick}
			sx={[actualStyles, { borderRadius: rounded ? "50px" : "0" }]}
			variant={variant}
			startIcon={startIcon}
			endIcon={endIcon}
			color={color}
			type={buttonType}
		>
			{children}
		</ButtonMUI>
	);
}
