/* eslint-disable react/function-component-definition */
import { ChevronLeft, Menu } from "@mui/icons-material";
import { IconButton, Stack, Toolbar } from "@mui/material";
import { useState } from "react";
import peopleLogo from "src/assets/logo_white.png";
import PopoverComponent from "src/v2/components/Popover/Popover";
import { NotificationContainer } from "src/v2/components/molecules/NotificationContainer/NotificationContainer";
import { TimeNotification } from "src/v2/components/molecules/TimeNotification/TimeNotification";
import { useDynamicViewTagContext } from "src/v2/hooks";
import { NotificationBadge } from "../../atoms/NotificationsBadge/NotificationsBadge";
import { SearchBar } from "../../molecules";
import { UserInformationPopover } from "../UserInformationPopover";
import {
	LogoAndButtonsContainer,
	NavBarContainer,
	NavBarInnerContainer,
	NavBarLogoContainer,
	bellStyles,
} from "./NavBarStyleComponents";
import { INavBarProps } from "./types";

const NavBar: React.FC<INavBarProps> = ({ open = true, setOpen = () => {} }) => {
	const handleDrawer = () => {
		setOpen(!open);
	};
	const { bellIcon } = bellStyles;
	const [openPopover, setOpenPopover] = useState(false);
	const [isNotificationChecked, setIsNotificationChecked] = useState(false);
	const { TimeNotificationData } = useDynamicViewTagContext();

	const handleClosePopover = () => {
		setOpenPopover(false);
	};

	const handleTimeNotificationCheckboxChange = () => {
		setIsNotificationChecked(!isNotificationChecked);
	};
	const notifications = [
		<TimeNotification
			key='TimeNotification'
			checked={isNotificationChecked}
			handleTimeNotificationCheckboxChange={handleTimeNotificationCheckboxChange}
		/>,
	];

	return (
		<NavBarContainer data-testid='nav-bar-container'>
			<NavBarInnerContainer>
				<LogoAndButtonsContainer>
					<Toolbar variant='regular'>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							edge='start'
							onClick={handleDrawer}
							sx={{
								...(open && { display: "none" }),
							}}
						>
							<Menu fontSize='large' sx={{ color: "white" }} />
						</IconButton>
						{open && (
							<IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawer}>
								<ChevronLeft fontSize='large' sx={{ color: "white" }} />
							</IconButton>
						)}
					</Toolbar>
					<NavBarLogoContainer>
						<a href='/'>
							<img src={peopleLogo} alt='Logo' className='max-h-16' />
						</a>
					</NavBarLogoContainer>
				</LogoAndButtonsContainer>

				<Stack direction='row' spacing={2} alignItems='center'>
					<PopoverComponent
						isOpen={openPopover}
						mainComponent={
							<IconButton sx={bellIcon}>
								{!isNotificationChecked ? (
									<NotificationBadge number={TimeNotificationData?.values.notification_number} />
								) : (
									<NotificationBadge number={0} />
								)}
							</IconButton>
						}
						positions={["bottom"]}
						withWidth={false}
						onClick={(newState) => setOpenPopover(newState)}
					>
						<NotificationContainer handleClose={handleClosePopover}>{notifications}</NotificationContainer>
					</PopoverComponent>
					<SearchBar />
					<UserInformationPopover />
				</Stack>
			</NavBarInnerContainer>
		</NavBarContainer>
	);
};

export default NavBar;
