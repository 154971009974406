/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from "react";
import { TextField } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import resolverManager from "src/constants/resolverManager";
import { useAlert } from "src/hooks/alert/useAlert";
import { ALERT_STATE } from "src/v2/components/Alert/IAlert";
import { Alert } from "src/v2/components/Alert/Alert";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { ErrorMessageInForm, FormSectionContainer } from "../../../../components/forms/FormComponents";
import { CandidateProps } from "../CandidateProps";
import { InputContainer, LabelForm } from "./TransformFormContainerStyles";
import { ICandidateTransformForm } from "../ITransformForm";
import { TransformModal } from "../TransformModal/TransformModal";
import CalendarTransform from "../CalendarTransform/CalendarTransform";

export function TransformFormContainer({ candidate }: CandidateProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { alertState, updateAlertState } = useAlert();
  const textFieldClasses="w-full max-w-sm";
  const divTextClasses="w-full max-w-sm space-y-2 mb-3";
  const widthFullClass="w-full";
  const form = useForm<ICandidateTransformForm>({
    defaultValues: {
      first_name: candidate.first_name,
      last_name: candidate.last_name,
      preferred_name: candidate.preferred_name,
      start_date: candidate.start_date,
    },
    mode: "onBlur",
    resolver: resolverManager.transformcandidateInformationSchema,
  });
  const { register, handleSubmit, formState, getValues, setValue, trigger } = form;
  const { errors, isValid } = formState;
  const onSubmitForm = () => {
      updateAlertState({
        show: true,
        data: {
          body: "Candidate promoted!",
          color: ALERT_STATE.success,
          title: "",
        },
      });
  };
  const onCreatingId = () => {
    setValue("ioet_id", (candidate.first_name[0] + candidate.last_name).toLowerCase());
    trigger("ioet_id");
    updateAlertState({
      show: true,
      data: {
        body: "ioet ID Generated!",
        color: ALERT_STATE.success,
        title: "",
      },
    });
  };
  const onCreatingEmail = () => {
    setValue("ioet_email", `${candidate.first_name}.${candidate.last_name}@ioet.com`.toLowerCase());
    trigger("ioet_email");
    updateAlertState({
      show: true,
      data: {
        body: "ioet Email Generated!",
        color: ALERT_STATE.success,
        title: "",
      },
    });
  };

  return (
    <>
      {alertState.show && <Alert {...alertState.data} />}
      {isModalOpen && (
        <TransformModal
          text='Are you sure you want to convert this candidate into an employee?'
          IhandleSubmit={onSubmitForm}
          onClose={closeModal}
        />
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(openModal)}>
          <FormSectionContainer>
            <div className={widthFullClass}>
              <div className='w-full lg:h-full flex flex-col'>
                <InputContainer>
                  <LabelForm htmlFor='ioet_id'>ioet ID</LabelForm>
                  <div className={divTextClasses}>
                    <TextField
                      id='ioet_id'
                      {...register("ioet_id")}
                      className={widthFullClass}
                      disabled
                      data-testid='candidate-input-ioetid'
                    />
                    <ErrorMessageInForm>{errors.ioet_id?.message}</ErrorMessageInForm>

                    <Button
                      variant='contained'
                      color='secondary'
                      disabled={getValues("ioet_id") !== undefined}
                      onClick={onCreatingId}
                    >
                      Generate ioet ID
                    </Button>
                  </div>
                </InputContainer>
                <InputContainer>
                  <LabelForm htmlFor='first_name'>First name </LabelForm>
                  <div className={textFieldClasses}>
                    <TextField className={textFieldClasses} id='first_name' {...register("first_name")} />
                    <ErrorMessageInForm>{errors.first_name?.message}</ErrorMessageInForm>
                  </div>
                </InputContainer>
                <InputContainer>
                  <LabelForm htmlFor='last_name'>Last name </LabelForm>
                  <div className={textFieldClasses}>
                    <TextField className={textFieldClasses} id='last_name' {...register("last_name")} />
                    <ErrorMessageInForm>{errors.last_name?.message}</ErrorMessageInForm>
                  </div>
                </InputContainer>
                <InputContainer>
                  <LabelForm htmlFor='preferred_name'>Preferred name </LabelForm>
                  <TextField className={textFieldClasses} id='preferred_name' {...register("preferred_name")} />
                </InputContainer>
                <InputContainer>
                  <LabelForm htmlFor='ioet_email'>ioet Email </LabelForm>
                  <div className={divTextClasses}>
                    <TextField
                      id='ioet_email'
                      className={widthFullClass}
                      {...register("ioet_email")}
                      data-testid='candidate-input-ioetemail'
                    />
                    <ErrorMessageInForm>{errors.ioet_email?.message}</ErrorMessageInForm>
                    <Button
                      disabled={!!getValues("ioet_email")}
                      variant='contained'
                      onClick={onCreatingEmail}
                      endIcon={<EmailIcon />}
                    >
                      Create Google Account
                    </Button>
                  </div>
                </InputContainer>
                <InputContainer>
                  <LabelForm htmlFor='start_date'>Start date </LabelForm>
                  <CalendarTransform id='start_date' />
                </InputContainer>
                <Button
                  className={textFieldClasses}
                  type='submit'
                  variant='contained'
                  color='success'
                  disabled={!isValid}
                  data-testid='candidate-button-submit'
                  style={{marginTop:"2rem"}}
                >
                  Convert Candidate
                </Button>
              </div>
            </div>
          </FormSectionContainer>
        </form>
      </FormProvider>
    </>
  );
}
