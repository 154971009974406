import React from "react";
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import { ISelectProps } from "./types";

const Select: React.FC<ISelectProps> = ({
	variant = "outlined",
	label,
	disabled,
	value,
	options,
	sx,
	size,
	fullWidth,
	onChange
}) => {
	return (
		<FormControl variant={variant} fullWidth={fullWidth}>
			<InputLabel id='select-label'>{label}</InputLabel>
			<MuiSelect labelId='select-label' value={value} label={label} disabled={disabled} sx={sx} size={size} onChange={onChange}>
				{options.map((option, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<MenuItem key={index} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</MuiSelect>
		</FormControl>
	);
};

export default Select;
