import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Avatar } from "src/v2";
import PopoverComponent from "../../Popover/Popover";
import { UserInformation } from "../../molecules/UserInformation";
import { Button } from "../../atoms/Button";
import useAuth from "../../../../hooks/useAuth";
import User from "../../../../types/userType";
import { LOGOUT_URL } from "../../../../constants/urlConstants";
import { UserInformationMenu } from "./UserInformationStyleComponents";

function UserInformationPopover() {
	const [user, setUser] = useState<User | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const { getUserInformation } = useAuth();

	useEffect(() => {
		getUserInformation().then((userInfo) => {
			setUser(userInfo);
			setLoading(false);
		});
	}, []);

	return loading ? (
		<CircularProgress sx={{ color: "white" }} aria-label='spinbutton' role='progressbar' />
	) : (
		<PopoverComponent mainComponent={<Avatar alt='User Avatar' width='4rem' srcImage={user?.picture}/>} withWidth={false}>
			<UserInformationMenu aria-label='usermenu' role='menu'>
				<UserInformation user={user} showName showAdminRole showEmail />
				<Button
					variant='text'
					color='secondary'
					onClick={() => {
						window.location.href = LOGOUT_URL;
					}}
					icon='logout'
					text='Logout'
					iconPosition='left'
				/>
			</UserInformationMenu>
		</PopoverComponent>
	);
}

export default UserInformationPopover;
