import { CSSProperties, FC, ForwardedRef, ReactNode, forwardRef } from "react";
import cn from "classnames";
import styles from "./Detail.module.css";

/**
 * Detail
 *
 * @sizes
 * - Default
 *   - Size: 0.9rem
 * 	 - large: 1.1rem
 * @default
 * - Weight: 500
 * - Height: 150%
 * - Family: Poppins
 */

export type TypographyProps<T> = {
    children?: ReactNode;
    className?: string;
    id?: string;
    small?: boolean;
    large?: boolean;
    style?: Partial<CSSProperties>;
    ref?: ForwardedRef<T>;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    color?: CSSProperties["color"];
    bold?: boolean;
};

const Detail: FC<TypographyProps<HTMLSpanElement>> = forwardRef(
	({ children, className, large, bold, style, ...props }, forwardedRef) => {
		const classes: string = cn(styles["detail"], className, {
			[styles["size--large"]]: large,
			[styles["weight--bold"]]: bold,
		});

		return (
			<span style={{ ...style }} ref={forwardedRef} className={classes} {...props}>
				{children}
			</span>
		);
	}
);

export default Detail;
