import React from "react";
import { Typography as MuiTypography } from "@mui/material";
import { ITypography } from "./types";

function Typography({
	variant = "body1",
	color = "inherit",
	align = "inherit",
	gutterBottom = false,
	noWrap = false,
	paragraph = false,
	children,
	sx = {},
}: ITypography) {
	return (
		<MuiTypography
			variant={variant}
			color={color}
			align={align}
			gutterBottom={gutterBottom}
			noWrap={noWrap}
			paragraph={paragraph}
			sx={sx}
		>
			{children}
		</MuiTypography>
	);
}

export default Typography;
