/* eslint-disable react/function-component-definition */
import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from "react";

type SidebarContextType = {
	showSidebar: boolean;
	setShowSidebar: Dispatch<SetStateAction<boolean>>;
};
interface IDynamicViewTagsProviderProps {
	children: React.ReactNode;
}

export const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider = ({ children }: IDynamicViewTagsProviderProps) => {
	const [showSidebar, setShowSidebar] = useState(true);

	const contextValue: SidebarContextType = useMemo(() => {
		return { showSidebar, setShowSidebar };
	}, [showSidebar, setShowSidebar]);

	return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};
