import { useState } from "react";
import PeopleImage from "src/assets/ioet_people.png";
import { ICandidateTransform } from "./Transform";
import { Arrow, ImgCandidate, MainContainer, TitleContainer, Wrap } from "./TransformInfoStyles";

interface PassingFunction extends ICandidateTransform {
  index?: number;
  onClickPass: () => void;
}
export function TransformInfo({ first_name, last_name, img, onClickPass, index }: PassingFunction) {
  const fullName: string = `${first_name} ${last_name}`;
  const [arrowActive, setArrowActive] = useState(false);
  const handleArrowClick = () => {
    setArrowActive(!arrowActive);
    onClickPass();
  };
  const imageSource= !img ? PeopleImage : img;
  const rotateArrowClass=`w-5 h-5 inline-block relative mx-4 ${
    arrowActive ? "  transform rotate-45" : "transform -rotate-[135deg]"
  }`

  return (
    <MainContainer onClick={() => handleArrowClick()}>
      <Wrap>{index}</Wrap>
      <Wrap>
        <ImgCandidate src={imageSource} className='bg-white' />
      </Wrap>
      <TitleContainer aria-label="Candidate Name"> {fullName}</TitleContainer>
      <Wrap>
        <Arrow
          data-testid='candidate-name-button'
          className={rotateArrowClass}
          aria-label="Arrow"
        />
      </Wrap>
    </MainContainer>
  );
}
