import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { capitalizeFirstLetter, convertToLowerCase } from "src/utils/parsers";
import Button from "src/v2/components/atoms/Button/Button";
import { transformColumnLabel } from "../adapters/table.adapter";
import useDynamicViewTagContext from "./useDynamicViewModelContext"; /* change this import with the correct hook columns */

import { ViewTag } from "../models/dynamicViewTag.model";
import { chipEditViewTagComponent } from "../components/editViewTagsComponents/simpleEditViewTags";

const useColumns = () => {
	const context = useDynamicViewTagContext();
	if (context.DataDefinitionSchema == null) {
		return null;
	}

	return Object.keys(context.DataDefinitionSchema.fields).map((columnField) => {
		const { label, view_tag: viewTag, valueOptions, multiSelectOptions } = context.DataDefinitionSchema!.fields[columnField];

		const SelectedComponent = context.renderDynamicModelField(columnField);

		const columnLabel = transformColumnLabel(label);

		const aditionalProprerties = {
			...(viewTag === ViewTag.chip
				? {
						renderEditCell: (params: GridRenderCellParams) => {
							return chipEditViewTagComponent(params, multiSelectOptions!);
						},
				  }
				: {}),
			...(viewTag === ViewTag.date ? { type: "date" } : {}),
			...(viewTag === ViewTag.select ? { type: "singleSelect", valueOptions } : {}),
		};

		const column: GridColDef = {
			field: columnField,
			headerName: columnLabel,
			minWidth: 200,
			headerAlign: "left",
			align: "left",
			editable: true,
			renderCell: (params) => {
				const fieldsToCapitalize = ["GENDER", "OFFICE"];
				const fieldsToLowerCase = ["CONTRACT TYPE"];

				const conditions = [
					{ check: () => fieldsToCapitalize.includes(columnLabel), transform: capitalizeFirstLetter },
					{ check: () => fieldsToLowerCase.includes(columnLabel), transform: convertToLowerCase },
				];
				const conditionMatch = conditions.find((condition) => condition.check());

				const rowsValues = conditionMatch ? conditionMatch.transform(params?.value) : params?.value;
				const ioetId = params.row.ioetId;
				return (
					<div
						style={{
							whiteSpace: "normal",
							wordBreak: "break-all",
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{SelectedComponent && SelectedComponent(rowsValues)}
						{columnLabel === "NAME" ? (
							<Button
								text=''
								icon='redirect'
								iconStyle={{ pointerEvents: "none" }}
								variant='text'
								iconPosition='left'
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									event.stopPropagation();
									window.open(`/person/${ioetId}`,"_blank", "noreferrer")
								}}
							/>
						) : null}
					</div>
				);
			},
			...aditionalProprerties,
		};

		return column;
	});
};

export default useColumns;
