import { createTheme } from "@mui/material"

export const InputTheme = createTheme({
    palette: {
        primary: {
            main: "#64b5f6",
        },
        secondary: {
            main: "#90a4ae",
        },
    },
});