import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDynamicViewTagContext } from "src/v2/hooks/";
import { PersonTimeNotification } from "../../atoms/PersonTimeNotification/PersonTimeNotification";
import ITimeNotificationProps, { Person } from "./ITimeNotification";
import { styles } from "./TimeNotificationStyleComponents";

export function TimeNotification({ checked, handleTimeNotificationCheckboxChange }: ITimeNotificationProps) {
	const [employeeNotifications, setEmployeeNotifications] = useState<Person[]>([]);
	const [month, setMonth] = useState<string>("");
	const { TimeNotificationData } = useDynamicViewTagContext();
	const { notificationText } = styles;

	useEffect(() => {
		setEmployeeNotifications(TimeNotificationData?.values.personList || []);
		setMonth(TimeNotificationData?.values.month || "");
	}, [TimeNotificationData]);

	const disableProps = { opacity: checked ? 0.5 : 1, userSelect: "none", pointerEvents: "none" };

	return (
		<Stack margin={1} title='stack'>
			<FormControlLabel
				sx={{ display: "flex", justifyContent: "space-between", margin: 0 }}
				control={
					<Checkbox
						checked={checked}
						aria-label='mark as read'
						color='default'
						onChange={handleTimeNotificationCheckboxChange}
					/>
				}
				label={<Typography sx={[notificationText, disableProps]}>{`Reviews for ${month}`}</Typography>}
				labelPlacement='start'
			/>
			<Typography textAlign='start' variant='body1' padding='0.5rem' sx={disableProps}>
				These people will complete one more year at ioet next month.
			</Typography>
			{employeeNotifications.length > 0 ? (
				<>
					{employeeNotifications.map((notification, index) => (
						<div key={`${notification.id.toString() + index}`} title='notification'>
							<PersonTimeNotification disabled={checked} notification={notification} />
						</div>
					))}
					<Typography textAlign='center' variant='body1' sx={{ ...disableProps, p: 2 }} title='resumeText'>
						{`${employeeNotifications.length} people are on the list`}
					</Typography>
				</>
			) : (
				<Typography textAlign='center' variant='body1' sx={{ ...disableProps, p: 2 }} title='resumeText'>
					No new notifications
				</Typography>
			)}
		</Stack>
	);
}
