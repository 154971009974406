
import { Dict } from "src/v2/models";
import { CompensationHistorySection } from "./CompensationHistorySection/CompensationHistorySection";

export function CompensationTab(personInformation: Dict, key: string) {
	const { personInformation: personData } = personInformation;

	return (
		<div style={{ paddingLeft: "5%" }} key={key} data-testid='compensation-tab-container'>
			<CompensationHistorySection compensationInformation={personData.compensation} />
		</div>
	);
}
